import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

import DownloadPageService from "./services/DownloadPageService";
import withHeader from "./HOCs/withHeader";
import { DownloadFaq } from "./components/DownloadFaq";
import DownloadSections from "./components/download-page-sections";
import { getContent } from "./newActions";
import config from "./config";
import ImageModal from "./components/image-modal";
import CoverSection from "./components/downloads-cover-section/cover-section";

import "./variables.scss";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "animate.css";

import Logo from "../../assets/images/down-zone-logo.svg";
import download from "./icons/download.png";
import liner from "./icons/liner.png";
import mouse from "./icons/mouse.svg";

function DownloadsPage() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [content, setContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [modalImageArray, setModalImage] = useState([]);
  const [activeKey, setActiveKey] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageLoaded, setPageLoaded] = useState(false);

  const dispatch = useDispatch();

  const dataOPeningDuration = 800;
  const modalBox = document.getElementById("modal_box");
  const loadingPage = document.getElementById("loading-page");

  const openModalHandler = (arr, i, type, MlsArr = []) => {
    const chosenElementId = arr[i].id;
    const chosenMlsElement = MlsArr.find((elem) => elem.id === chosenElementId);

    const chosenMlsElementIndex = MlsArr.indexOf(chosenMlsElement);
    setModalImage(MlsArr.length ? MlsArr : arr);
    setTimeout(() => {
      setShowModal(!showModal);
    }, 100);
    setActiveKey((MlsArr.length ? chosenMlsElementIndex - 1 : i).toString());
    if (type) {
      setModalValue({
        image: `${config.imageUrl}${chosenMlsElement.id}`,
        index: MlsArr.length ? chosenMlsElementIndex : i,
        id: chosenMlsElement.id,
      });
      modalBox?.classList.remove("animate__zoomOut");
      modalBox?.classList.add("animate__zoomIn");
    }
  };

  const closeModalHandler = () => {
    setModalImage([]);
    setActiveKey(0);
    modalBox?.classList.remove("animate__zoomIn");
    modalBox?.classList.add("animate__zoomOut");
    setModalValue("");

    setTimeout(() => {
      setShowModal(!showModal);
    }, 800);
  };

  const changeLoadStatus = () => {
    setPageLoaded(true);
  };

  useEffect(() => {
    window.addEventListener("load", changeLoadStatus);
    return () => window.removeEventListener("load", changeLoadStatus);
  }, []);

  useEffect(() => {
    DownloadPageService.getContent(params.id).then((res) => {
      if (res.status === 404) {
        navigate("/404");
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        return;
      }
      {
        res.success === true &&
          setTimeout(() => {
            setLoading(false);
          }, dataOPeningDuration);
      }

      setContent(res.data?.item);
    });

    dispatch(getContent(params.id));
  }, [data]);

  useEffect(() => {
    if (loading && document.body) {
      document.body.style.overflow = "hidden";
      if (loadingPage !== null) {
        loadingPage?.classList.add("animate__fadeOut");
      }
    } else if (document.body) {
      document.body.style.overflow = "unset";
    }
  }, [loading]);

  return (
    <>
      <div className="relative">
        <CoverSection
          pageLoaded={pageLoaded}
          loading={loading}
          APIData={content}
          idCover={"downloads"}
          img={content?.cover ? config.imagesBaseUrl + content?.cover : null}
          title={"DOWNLOAD ZONE"}
          sub_title={content?.address ?? ""}
          agent={content?.agent?.first_name + " " + content?.agent?.last_name}
          shoot_date={
            content?.shoot_data
              ? moment(content?.shoot_data).format("DD/MM/YYYY")
              : "12/02/2021"
          }
          services={content?.services}
          services_packages={content?.packages?.pricing_services_list}
          download_icon={download}
          liner_icon={liner}
          mouse_icon={mouse}
          video={
            content?.cover_video
              ? config.apiBaseUrl + content?.cover_video
              : null
          }
        />
        <DownloadSections
          loading={loading}
          open={openModalHandler}
          content={content}
        />
        <DownloadFaq />
        {!loading && (
          <ImageModal
            show={showModal}
            close={closeModalHandler}
            value={modalValue}
            activeKey={activeKey}
            modalImageArray={modalImageArray}
          />
        )}
      </div>
    </>
  );
}

export default DownloadsPage;
