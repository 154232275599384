import React, { useEffect } from 'react';
import ReactPlayer from 'react-player';
import closeImg from '../../images/closeImg.svg';
import './style.scss';

function VideoModal({ close, show, value }) {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  const handleClose = () => {
    close(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <div className={show ? 'modal_cover_section_of_video' : 'hide_modal'}>
      <div className="video_modal_section" onClick={handleClose}>
        <div className="video_of_modal_section">
          <div className="button_section">
            <div className="close_section">
              <img src={closeImg} onClick={() => handleClose()} />
            </div>
          </div>
          <div className="video_grabber">
            <ReactPlayer url={value} className="video_of_watch_preview" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VideoModal;
