import * as JSZip from 'jszip';
import * as JSZipUtils from './script/jszip-utils';
import './style.scss';
import { saveAs } from 'file-saver';
import { store } from '../../store';
import { downloadFile } from 'pages/download-zone/components/DownloadButton/DownloadFile';

let downloadedObject = [];
export const downloadProgressActionCreator = (payload) => {
  return { type: 'DOWNLOAD_PROGRESS', payload };
};

export let zip = null;
export const filesNamesArr = [];
export let setIsDownloadedAbort = null;

const downloadAsZip = (
  photosLinks,
  setIsDownloaded,
  sectionName = 'Download-HD-Photos.zip',
  secondLink = [],
  folderCount = '1',
  firstName = '',
  secondName = ''
) => {
  if (!photosLinks.length) {
    return;
  }
  if (folderCount === '2' && !secondLink.length) {
    return;
  }
  setIsDownloadedAbort = setIsDownloaded;
  setIsDownloaded(true);

  let urlArr = [];
  if (folderCount === '2') {
    const linksLength = photosLinks.length;
    const firstPartOfLink = photosLinks.slice(0, linksLength / 2);
    const secondPartOfLink = photosLinks.slice(linksLength / 2);
    firstPartOfLink.map((elem) => (elem.folderName = firstName));
    secondPartOfLink.map((elem) => (elem.folderName = secondName));
  }

  photosLinks.map((el) => {
    urlArr.push({ urlForDownload: `/api/downloads/download/${el.id}`, ...el });
    filesNamesArr.push(el.name);
  });

  let count = 0;

  window.onbeforeunload = (event) => {
    return () => {
      // confirm("Download is in progress, exit anyway?");
      zip.remove();
    };
  };

  const downloadSingleFile = (url) => {
    downloadFile([url.downloadLink]);
    setIsDownloaded(false);
    window.onbeforeunload = null;
  };

  const downloadMultipleFiles = (url) => {
    downloadedObject.push({
      id: url.id,
      name: url.name,
      folderName: url.folderName,
      loaded: 0,
      total: +url.size,
      progress: 0,
    });

    const fileName = `${url.name}`;
    zip = new JSZip();
    JSZipUtils.JSZipUtils.getBinaryContent(url.urlForDownload, {
      callback: (err, data) => {
        if (err) {
          console.error(err);
        }
        zip.folder(url.folderName).file(`${fileName}`, data, { binary: true });
        count++;
        if (count === urlArr.length) {
          zip
            .generateAsync({
              type: 'blob',
              compression: 'DEFLATE',
              streamFiles: true,
              compressionOptions: {
                level: 6,
              },
            })
            .then((content) => {
              saveAs(content, `${sectionName}.zip`);
            })
            .then((res) => {
              setIsDownloaded(false);
              window.onbeforeunload = null;
              store.dispatch(downloadProgressActionCreator([]));
              window.setTimeout(() => {
                zip = new JSZip();
              }, 100);
            });
        }
      },
      progress: (e) => {
        setTimeout(() => {
          const currentElemPath = e.path.split('/');
          const currentElemId = currentElemPath[currentElemPath.length - 1];
          const currentElem = downloadedObject.find(
            (elem) => elem.id === currentElemId
          );
          currentElem.loaded = e.loaded;
          currentElem.progress = Math.floor((e.loaded * 100) / e.total);
          store.dispatch(downloadProgressActionCreator(downloadedObject));
        }, 0);
      },
    });
  };

  downloadedObject = [];

  urlArr.forEach((url) => {
    if (urlArr.length === 1) {
      downloadSingleFile(url);
    } else {
      downloadMultipleFiles(url);
    }
  });
};

export default downloadAsZip;
