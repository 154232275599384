import React from "react";
import "./style.module.scss";

const SignInButton = () => {
    return (
        <a href="/signin" className="sign-in_button">
            Log In
        </a>
    );
};

export default SignInButton;
