import React from "react";
import "../quantity-text/styles.scss";

export const Quantity = ({lengthOfItems}) => {
    return (
        <>
            <span className="quantity_text">
                Quantity:
                <span> {lengthOfItems} </span>
            </span>
        </>
    );
};

export default Quantity;