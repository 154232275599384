import React, { useState } from 'react';
import Outside from '../Outside';
import { Videos } from '../Videos/Videos';
import plus from '../../icons/plus.svg';
import minus from '../../icons/minus.svg';
import './style.scss';

const FaqComponent = ({ sections }) => {
  const [openCart, setOpenCart] = useState(null);
  return (
    <>
      {sections.map((el, i) => (
        <Outside key={el.id}>
          <div
            key={el.id}
            action={openCart == el.id ? () => setOpenCart(null) : null}
          >
            <div
              onMouseDown={(e) => {
                setOpenCart(openCart == el.id ? null : el.id);
                e.stopPropagation();
              }}
            >
              <div className={`box  ${openCart == el.id ? 'active' : ''}`}>
                <div
                  className={`title_wrapp ${openCart == el.id ? 'show' : ''}`}
                >
                  <h3 className="title">{el.question}</h3>
                </div>
                <div
                  className={`description ${openCart == el.id ? 'show' : ''}`}
                >
                  {el.answer}
                  {!!el.video && <Videos video={el?.video} />}
                </div>
                <button aria-label="open" type="button" className="icons_wrapp">
                  {openCart == el.id ? <img src={minus} /> : <img src={plus} />}
                </button>
              </div>
            </div>
          </div>
        </Outside>
      ))}
    </>
  );
};

export default FaqComponent;
