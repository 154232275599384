export const cancelSelection = (setLinks, setSecondLink, setThirdLink, setCheckedInput) => {
    setLinks([]);
    setSecondLink([]);
    setThirdLink([]);
    setCheckedInput({});
};

export const selectAll = (data_1, data_2, setLinks, setSecondLink, setCheckedInput) => {
    setLinks([]);
    setSecondLink([]);
    data_1.map(elem => setLinks(prev => [...prev, elem]));
    data_2.map(elem => setSecondLink(prev => [...prev, elem]));
    let res = {};
    data_1.forEach(el => {
        res[el.id] = true;
    });
    data_2.forEach(el => {
        res[el.id] = true;
    });
    setCheckedInput(res);
};

const choseSecondLink = (element, serverData, secondLink, setSecondLink) => {
    const secondElement = serverData.filter(elem => elem.name.split('.')[0] === element.name.split('.')[0]);

    const isElementExisted = secondLink.filter(elem => elem.name.split('.')[0] === element.name.split('.')[0]).length;
    if (!secondLink.length) {
        setSecondLink(prevElem => [...prevElem, ...secondElement]);
        return;
    }
    if (!!isElementExisted) {
        const existedElement = secondLink.filter(elem => element.name.split('.')[0] !== elem.name.split('.')[0]);
        setSecondLink(existedElement);
    } else {
        setSecondLink(prevElem => [...prevElem, ...secondElement]);
    }
};

export const checkImage = (e,
                           element,
                           serverData,
                           links,
                           setLinks,
                           secondLink,
                           setSecondLink,
                           checkedInput,
                           setCheckedInput,
                           selectedItemCount = 0) => {
    choseSecondLink(element, serverData, secondLink, setSecondLink);

    let data = {...checkedInput};

    if (selectedItemCount) {
        if (checkedInput.hasOwnProperty(element.id)) {
            delete data[element.id];
            setCheckedInput(data);
            let res = links.filter(el => el.id != element.id);
            setLinks(res);
        } else {
            setCheckedInput(prev => ({...prev, [element.id]: true}));
            setLinks(prev => [
                ...prev,
                {
                    downloadLink: element.downloadLink,
                    id: element.id,
                    name: element.name,
                    size: element.size
                }
            ]);
        }
    } else {
        setCheckedInput({[element.id]: true});
        setLinks([
            {
                downloadLink: element.downloadLink,
                id: element.id,
                name: element.name,
                size: element.size
            }
        ]);
    }
    e.stopPropagation();
};