import React, { useEffect, useState } from 'react';
import '../styles.scss';

const Buttons = ({ el }) => {
  const [copySuccess, setCopySuccess] = useState({ buttonId: null, url: '' });

  const copyText = (buttonId, text) => {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setCopySuccess({
      buttonId,
      text,
    });
  };

  const copyLink = (buttonId, url) => {
    const splittingArrayIntoFewString = url.iframe.split(' ');
    const findingSrcUrl = splittingArrayIntoFewString.find((el) =>
      el.includes('src')
    );
    const lengthOfSrcString = findingSrcUrl.split("'")[1];
    copyText(buttonId, lengthOfSrcString);
  };

  const copyEmbed = (buttonId, url) => {
    copyText(buttonId, url.iframe);
  };

  useEffect(() => {
    const copiedButton = setInterval(
      () => setCopySuccess({ buttonId: null, url: '' }),
      3 * 1000
    );
    return () => {
      clearInterval(copiedButton);
    };
  }, [copySuccess]);
  return (
    <div className="btn_wrapper">
      <button
        className={
          copySuccess.buttonId === 'videoLink' ? 'copied_btn' : 'download_btn'
        }
        onClick={() => copyLink('videoLink', el)}
      >
        <div className="liner_border"></div>
        {copySuccess.buttonId === 'videoLink' ? (
          <span> Copied </span>
        ) : (
          <span> Copy link </span>
        )}
      </button>
      <button
        className={
          copySuccess.buttonId === 'embedLink' ? 'copied_btn' : 'download_btn'
        }
        onClick={() => copyEmbed('embedLink', el)}
      >
        <div className="liner_border"></div>
        {copySuccess.buttonId === 'embedLink' ? (
          <span> Copied </span>
        ) : (
          <span> Embed code </span>
        )}
      </button>
    </div>
  );
};

export default Buttons;
