import React from 'react';
import SignInButton from '../sign_in/sign_in_button';

import './style.module.scss';

const SignInDropdown = () => {
  return (
    <div className="sign-in-dropdown-wrapper">
      <div className="account-info">
        <a className="create-new-account" href="/register">
          Sign Up
        </a>
      </div>
      <div className="sign-in-btn-wrapper">
        <SignInButton />
      </div>
    </div>
  );
};

export default SignInDropdown;
