const sectionsId = [
    {title: "3d matterport", id: 16},
    {title: "Video/3d matterport", id: 12},
    {title: "Video", id: 12},
    {title: "Gallery", id: 10},
    {title: "Floor Plan", id: 14},
    {title: "Virtual Staging", id: 11},
    {title: "Feature sheet", id: 15},
];

export default sectionsId;
