import BaseRequestService from './BaseRequestService';

class DownloadPageService extends BaseRequestService {
  getContent = (id) => {
    return this.getRequest(`/api/downloads/center/${id}`);
  };
  getVideosById = (id) => {
    return this.getRequest(`/api/vimeo/download-links/${id}`);
    // api/downloads/center/tour/:id
  };
}

export default new DownloadPageService();
