import React, {useEffect, useState} from 'react';
import "../button/styles.scss"
import convertDate from '../../global/data-converter';

const updated = "updated";
const delivered = "delivered"

const Button = ({sectionName = '', APIData = [], sidebar = false, updatedData = {isDeliveryLatest: true}}) => {

    const [isupdate, setUpdate] = useState(true);
    const [section, setSection] = useState();
    const [lastDate, setLastDate] = useState([])

    useEffect(() => {
        if (sidebar) {
            APIData?.download_center_sections?.map(elem => {
                let lastDate = [];
                lastDate.push(elem.pivot.updated_date);
                setLastDate(prevState => [...prevState, elem.pivot.updated_date].sort())
            });
        }
    }, [section]);

    useEffect(() => {
        setSection(APIData?.download_center_sections?.filter(elem => (
                elem.name === `${sectionName}`
            )
        ));
    }, [APIData]);

    return (
        (!!APIData?.deliver_date && sidebar && updatedData.isDeliveryLatest)
            ? <button className="delivered">
             <span className="delivered_dates">
                 {
                     `${delivered}: ${convertDate(APIData?.deliver_date)}`
                 }
            </span>
            </button>
            : (((!!section && section?.length !== 0) || sectionName === 'heading') && updatedData.isDeliveryLatest)
                ? (<button className={updated ? "updated" : "delivered"}>
            <span className="delivered_dates"> {
                !!lastDate.length
                    ? (isupdate && `${updated}: ${convertDate(lastDate[lastDate.length - 1])} `)
                    : (isupdate && `${updated}: ${convertDate(section?.[0]?.pivot?.updated_date)} `)
            }
            </span>
                </button>)
                : (sidebar && (<button className={updated ? "updated" : "delivered"}>
            <span className="delivered_dates"> {
                (isupdate && `${updated}: ${convertDate(updatedData.sectionsWithLastDate?.[0].pivot.updated_date)} `)
            }
            </span>
                </button>))
    )
}


export default Button;

