import React, { useEffect, useState } from "react";
import Swiper from "react-id-swiper";

import arrowRight from "../../images/arrowRight.svg";
import arrowLeft from "../../images/arrowLeft.svg";
import closeImg from "../../images/closeImg.svg";
import "./style.scss";

import "swiper/css/swiper.css";
import "animate.css";

const params = {
  breakpoints: {
    320: {
      noSwiping: false,
      loop: true,
      rebuildOnUpdate: true,
      runCallbacksOnInit: true,
      spaceBetween: 50,
    },
    992: {
      noSwiping: true,
      loop: true,
      spaceBetween: 70,
    },

    1000: {
      spaceBetween: 120,
    },
  },
  slidesPerView: "auto",
  loop: true,
  spaceBetween: 120,
  observer: true,
  observeParents: true,
  noSwiping: true,
  loopCreate: true,
};

const animationParams = {
  effect: "coverflow",
  centeredSlides: true,
  slidesPerView: "auto",
  coverflowEffect: {
    rotate: 0,
    stretch: 10,
    depth: 150,
    modifier: 1,
    slideShadows: true,
  },
};

function ImageModal({ close, show, modalImageArray, activeKey, value }) {
  const [swiper, setSwiper] = useState(null);
  const [chosenElementId, setChosenElementId] = useState("");
  const [chosenElementIndex, setChosenElementIndex] = useState("");
  const [innerHeight, setInnerHeight] = useState(null);
  const [modalPhotoHeight, setModalPhotoHeight] = useState(null);

  const hideHeader = document.getElementById("pricing");

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const handleClose = () => {
    close();
    setInnerHeight(0);
    setModalPhotoHeight(0);
  };

  useEffect(() => {
    if (swiper !== null) {
      swiper.update();
      swiper.loopCreate();
    }
  }, [chosenElementIndex]);

  useEffect(() => {
    if (!hideHeader) return;
    if (show) {
      document.body.style.overflow = "hidden";
      hideHeader.style.top = "-70px";
    } else {
      document.body.style.overflow = "unset";
      hideHeader.style.top = "0px";
    }
  }, [show]);

  useEffect(() => {
    setChosenElementId(value.id);
    setChosenElementIndex(value.index);
  }, [modalImageArray]);

  useEffect(() => {
    setChosenElementId(modalImageArray?.[chosenElementIndex]?.id);
  }, [chosenElementIndex]);

  useEffect(() => {
    const imageElement = document.getElementsByClassName(
      "swiper-container swiper-container-coverflow swiper-container-3d swiper-container-initialized swiper-container-horizontal"
    )[0];
    const topElement = document.getElementById("modal_cover_section_top");
    const bottomElement = document.getElementById("modal_cover_section_bottom");
    setInnerHeight(window.innerHeight);
    setModalPhotoHeight(imageElement?.offsetHeight);
    topElement.style.height = `${(innerHeight - modalPhotoHeight) / 2}px`;
    bottomElement.style.height = `${(innerHeight - modalPhotoHeight) / 2}px`;
    topElement.style.top = `${window.scrollY}px`;
    bottomElement.style.top = `${
      window.scrollY + window.innerHeight - (innerHeight - modalPhotoHeight) / 2
    }px`;
  });

  return (
    <div className={show ? "modal_cover_section" : "hide_modal"}>
      <div className="image_modal_section">
        <div
          className="modal_cover_section_top"
          id="modal_cover_section_top"
          onClick={handleClose}
        >
          <div className="close_section_imitation"></div>
        </div>
        <div
          className="modal_cover_section_bottom"
          id="modal_cover_section_bottom"
          onClick={handleClose}
        ></div>
        <div className="image_section animate__animated" id="modal_box">
          <div className="button_section">
            <div className="close_section">
              <img
                src={closeImg}
                onClick={close}
                id="closingModal"
                className="closing-button"
              />
            </div>
          </div>
          <Swiper
            {...params}
            {...animationParams}
            getSwiper={setSwiper}
            activeSlideKey={activeKey}
          >
            {modalImageArray?.map((item, i) => (
              <div key={i} className={"swiper-slide"}>
                <img
                  src={
                    item?.thumbnailLink?.slice(
                      0,
                      item?.thumbnailLink?.length - 3
                    ) + 1000
                  }
                  className="image_modal"
                  key={i}
                />
              </div>
            ))}
          </Swiper>

          <div className="btn_section">
            <img src={arrowLeft} onClick={goPrev} className="next_image_icon" />
            <img
              src={arrowRight}
              className="prev_image_icon"
              onClick={goNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageModal;
