import { scroll as scrollAnim } from '../scroll';

const scrollUpAndDown = () => {
  setTimeout(() => {
    const mainElem = document.getElementById('horiz');
    const elem = mainElem.getBoundingClientRect().top + window.scrollY;
    scrollAnim(elem - 80);
  }, 100);
};
const selectService = (
  serv,
  location,
  navigate,
  pathname,
  setSelectedServiceId = () => {},
  setServiceOpen = () => {},
  setShow = () => {}
) => {
  const horizElement = document.getElementById('horiz');
  if (!horizElement) {
    const newInterval = setInterval(() => {
      if (horizElement) {
        clearInterval(newInterval);
        setTimeout(() => {
          scrollUpAndDown();
        }, 500);
        setTimeout(() => {
          setSelectedServiceId(serv);
          setServiceOpen(false);
          setShow(false);
          const top =
            horizElement?.getBoundingClientRect().top + window.pageYOffset - 80;
          // scroll(top);
        }, 1000);
      }
    });
  } else {
    scrollUpAndDown();
    setSelectedServiceId(serv);
    setServiceOpen(false);
    setShow(false);
    if (pathname === '/') {
      const top =
        horizElement?.getBoundingClientRect().top + window.pageYOffset - 80;
      // scroll(top);
    } else {
      navigate('/');
      setTimeout(() => {
        const top =
          horizElement?.getBoundingClientRect().top + window.pageYOffset - 80;
        // scroll(top);
      }, 700);
    }
  }
};

export default selectService;
