import { apiManager } from "./apiManager";

export const usersApi = {
    getCompanyUsers: async (query) => await apiManager.get(`/dashboard/agents/users?${query ?? ''}`),
    getCompanyUser: async (id) => await apiManager.get(`/dashboard/agents/users/${id}`),
    createCompanyUser: async (params) => await apiManager.post('/dashboard/agents/users/store', params),
    updateCompanyUser: async (id, params) => await apiManager.post(`/dashboard/agents/users/update/${id}`, params),
    deleteCompanyUser: async (id) => await apiManager.delete(`/dashboard/agents/users/delete/${id}`),
    getTourByAgent: async (id, queryStr) => 
        await apiManager.get(`/dashboard/agents/tours/${id}${queryStr ? `?${queryStr}` : ""}`),
}