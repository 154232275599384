import { apiManager } from './apiManager';

export const toursApi = {
  getTours: async ({ queryStr }) =>
    await apiManager.get(`/dashboard/tours/tours?${queryStr}`),
  getTourById: async ({ tourId }) =>
    await apiManager.get(`/dashboard/tours/tours/${tourId}`),
  getPricingCompany: async (params) =>
    await apiManager.get('/dashboard/tours/dashboard-pricing-company', params),
  getServices: async (price) =>
    await apiManager.get(`/dashboard/tours/services/${price}`),
  getCompanyServices: async () =>
    await apiManager.get('/dashboard/tours/company-services'),
  getPackages: async (price) =>
    await apiManager.get(`/dashboard/tours/packages/${price ?? 1000}`),
  createTour: async (tour) => await apiManager.post('/dashboard/tours', tour),
  createMarketingTour: async (params) =>
    await apiManager.post('/dashboard/tours/marketing/store', params),
  updateTour: async ({ tourId, body }) =>
    await apiManager.post(`/dashboard/tours/tours/update/${tourId}`, body),
  getCompanyUsers: async () => await apiManager.get('/dashboard/tours/company-users'),
};
