import React, { useState, useEffect } from 'react';
import Button from '../../../button/index';
import '../video_and_matterport/styles.scss';
import '../video_and_matterport/matterport.styles.scss';
import Videos from '../video_and_matterport/video_component';
import Quantity from '../../../quantity-text';
import AddressHolder from '../../../address-holder';

const VideoAndMatterport = ({ APIData, content }) => {
  const [extraVideo, setExtraVideo] = useState([]);

  useEffect(() => {
    if (
      !(
        !APIData?.extras ||
        !Array.isArray(extraVideo) ||
        APIData?.extras.length === 0
      )
    ) {
      const extraVideoArr = JSON.parse(APIData?.extras);
      setExtraVideo(extraVideoArr);
    }
  }, [APIData]);

  const brandedVideo = APIData?.videos?.vimeo_branded;
  const unBrandedVideo = APIData?.videos?.vimeo_unbranded;
  const brandedUnbrandedVideos = APIData?.videos;

  const objectCounter = (passedObject) => {
    if (passedObject) {
      const valuesOfObject = Object.values(passedObject);

      return valuesOfObject.filter((elem) => !!elem).length;
    }
  };

  const lengthOfAllVideos = () => {
    if (
      !!APIData?.videos?.background_video &&
      Object.keys(brandedUnbrandedVideos).includes('background_video')
    ) {
      return extraVideo.length + objectCounter(brandedUnbrandedVideos) - 1;
    } else {
      return extraVideo.length + objectCounter(brandedUnbrandedVideos);
    }
  };

  const textOfBrandedVideo =
    'Download the HD Video file or Copy the direct link to your Video only';
  const textOfUnBrandedVideo =
    'Download the HD Video file or Copy the direct link to your Video only';
  const textOfExtraVideo =
    'Download the HD Video file or Copy the direct link to your Video only';

  return (
    <>
      {(unBrandedVideo || brandedVideo || !!extraVideo.length) && (
        <section className="video_and_matterport" id="12">
          <div className="content">
            <div className="main_information">
              <h2 className="title">
                <span>VIDEO</span>
              </h2>
              <div className="button_block">
                <Button sectionName="Video" APIData={APIData} />
              </div>
              <div className="videos_address_holder">
                <AddressHolder APIData={APIData} />
              </div>
            </div>
            <div className="quantity_holder">
              <Quantity lengthOfItems={lengthOfAllVideos()} />
            </div>
            <div className="grid-container">
              {APIData?.videos?.vimeo_branded && (
                <div className="video-container">
                  {!!brandedVideo && (
                    <div className="branded_unbranded_video-container">
                      <Videos
                        video={brandedVideo}
                        title="Branded Video"
                        downloadVideoButton={brandedVideo}
                        textOfVideos={textOfBrandedVideo}
                        iframe={APIData?.videos?.vimeo_branded}
                      />
                    </div>
                  )}
                </div>
              )}
              {APIData?.videos?.vimeo_unbranded && (
                <div className="video-container">
                  {!!unBrandedVideo && (
                    <div className="branded_unbranded_video-container">
                      <Videos
                        video={unBrandedVideo}
                        title={'Unbranded Video'}
                        downloadVideoButton={unBrandedVideo}
                        textOfVideos={textOfUnBrandedVideo}
                        iframe={APIData?.videos?.vimeo_unbranded}
                      />
                    </div>
                  )}
                </div>
              )}
              {extraVideo.map((elem, index) => (
                <div className="video-container" key={index}>
                  {!!extraVideo && (
                    <div className="branded_unbranded_video-container">
                      <Videos
                        video={elem?.link}
                        title={elem?.title}
                        downloadVideoButton={elem?.link}
                        textOfVideos={textOfUnBrandedVideo}
                        iframe={elem?.link}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className="multi-liner"></div>
          </div>
        </section>
      )}
    </>
  );
};

export default VideoAndMatterport;
