import "../../../config";

export const leftSidebarData = [
    {
        active_icon: "/files/sidebar-icons/globus.svg",
        title: "PROPERTY \n Website",
        id: 9,
        icon: "/files/sidebar-icons/globus.svg",
        visible: 1,
        section_id: "#property_section",
        offSet: "-108rem",
        key: "property_web",
        services: 'PROPERTY WEBSITE'
    },
    {
        active_icon: "/files/sidebar-icons/video-frame.svg",
        title: "video",
        id: 12,
        icon: "/files/sidebar-icons/video-frame.svg",
        visible: 1,
        section_id: "#insta_section",
        offSet: "-80rem",
        key: "extras",
        services: 'AGENT VIDEO INTRO'
    },
    {
        active_icon: "/files/sidebar-icons/matterport.svg",
        title: "3D\n MATTERPORT",
        id: 16,
        icon: "/files/sidebar-icons/matterport.svg",
        visible: 1,
        section_id: "#3d_matterport",
        offSet: "-60rem",
        key: "matterport",
        services: '3D MATTERPORT TOUR'
    },
    {
        active_icon: "/files/sidebar-icons/social_media.svg",
        title: "SOCIAL MEDIA\n CONTENT",
        id: 17,
        icon: "files/sidebar-icons/social_media.svg",
        visible: 1,
        section_id: "#insta_section",
        offSet: "-71rem",
        key: "instagram",
        services: 'INSTAGRAM (Social Media) Teaser'
    },
    {
        active_icon: "/files/sidebar-icons/Frames.svg",
        title: "GALLERY",
        id: 10,
        icon: "/files/sidebar-icons/Frames.svg",
        visible: 1,
        section_id: "#gallery_section",
        offSet: "-30rem",
        key: "folder_completed_photo",
        services: 'TWILIGHT PHOTOGRAPHY'
    },
    {
        active_icon: "/files/sidebar-icons/floor-frame.svg",
        title: "FLOOR\n PLAN",
        id: 14,
        icon: "files/sidebar-icons/floor-frame.svg",
        visible: 1,
        section_id: "#floor",
        offSet: "-71rem",
        key: "floor_plans",
        services: 'FLOOR PLANS'
    },
    {
        active_icon: "/files/sidebar-icons/virtual-staging.svg",
        title: "Virtual \n Staging",
        id: 11,
        icon: "/files/sidebar-icons/virtual-staging.svg",
        visible: 1,
        section_id: "#virtual_staging",
        offSet: "-70rem",
        key: "virtual_staging",
        services: 'VIRTUAL STAGING / per photo'
    },
    {
        active_icon: "/files/sidebar-icons/featureSheet.svg",
        title: "FEATURE\n SHEET",
        id: 15,
        icon: "/files/sidebar-icons/featureSheet.svg",
        visible: 1,
        section_id: "15",
        offSet: "-67rem",
        key: "feature_sheet",
        services: 'FEATURE SHEET'
    }
];

export const leftSidebarDataMob = [
    {
        active_icon: "/files/sidebar-icons/globus.svg",
        title: "PROPERTY\n WEBSITE",
        id: 9,
        icon: "/files/sidebar-icons/globus.svg",
        visible: 1,
        section_id: "#property_section",
        offSet: "-233rem",
        key: "property_web",
        services: 'PROPERTY WEBSITE'
    },
    {
        active_icon: "/files/sidebar-icons/video-frame.svg",
        title: "video",
        id: 12,
        icon: "/files/sidebar-icons/video-frame.svg",
        visible: 1,
        section_id: "#insta_section",
        offSet: "-140rem",
        key: "extras",
        services: 'AGENT VIDEO INTRO'
    },
    {
        active_icon: "/files/sidebar-icons/matterport.svg",
        title: "3D\n MATTERPORT",
        id: 16,
        icon: "/files/sidebar-icons/matterport.svg",
        visible: 1,
        section_id: "#3d_matterport",
        offSet: "-140rem",
        key: "matterport",
        services: '3D MATTERPORT TOUR'
    },
    {
        active_icon: "/files/sidebar-icons/social_media.svg",
        title: "SOCIAL MEDIA\n CONTENT",
        id: 17,
        icon: "files/sidebar-icons/social_media.svg",
        visible: 1,
        section_id: "#insta_section",
        offSet: "-71rem",
        key: "instagram",
        services: 'INSTAGRAM (Social Media) Teaser'
    },
    {
        active_icon: "/files/sidebar-icons/Frames.svg",
        title: "GALLERY",
        id: 10,
        icon: "/files/sidebar-icons/Frames.svg",
        visible: 1,
        section_id: "#gallery_section",
        offSet: "-70rem",
        key: "folder_completed_photo",
        services: 'TWILIGHT PHOTOGRAPHY'
    },
    {
        active_icon: "/files/sidebar-icons/floor-frame.svg",
        title: "FLOOR\nPLAN",
        id: 14,
        icon: "/files/sidebar-icons/floor-frame.svg",
        visible: 1,
        section_id: "#floor_section",
        offSet: "-115rem",
        key: "floor_plans",
        services: 'FLOOR PLANS'
    },
    {
        active_icon: "/files/sidebar-icons/virtual-staging.svg",
        title: "Virtual \n Staging",
        id: 11,
        icon: "/files/sidebar-icons/virtual-staging.svg",
        visible: 1,
        section_id: "#virtual_staging",
        offSet: "-145rem",
        key: "virtual_staging",
        services: 'VIRTUAL STAGING / per photo'
    },
    {
        active_icon: "/files/sidebar-icons/featureSheet.svg",
        title: "FEATURE\n SHEET",
        id: 15,
        icon: "/files/sidebar-icons/featureSheet.svg",
        visible: 1,
        section_id: "#15",
        offSet: "-130rem",
        key: "feature_sheet",
        services: 'FEATURE SHEET'
    }
];
