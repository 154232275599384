import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../left-side-addressbar/style.scss';
import Clock from '../left-side-addressbar/icons/clock.svg';
import convertDate from '../../../../global/data-converter';

const AddressBar = ({ APIData }) => {
  const params = useParams();
  const firstAddress = APIData?.address?.split(/[,]/)[0];
  const secondAddress = APIData?.address?.substring(
    APIData.address?.indexOf(',') + 1
  );
  const unitNumber = APIData?.unit_number;

  return (
    <div className="address_bar">
      <div className="city">
        <h1>{firstAddress}</h1>
        <div className="address_bar_unit">
          {' '}
          {unitNumber && `UNIT ${unitNumber}`}
        </div>
      </div>
      <div className="info">
        <div className="name">
          <h3 className="name">
            <span className="bold_text ">Agent: </span>
            <span className="name_of_agent">
              {' '}
              {APIData?.agent?.first_name} {APIData?.agent?.last_name}
            </span>
          </h3>
        </div>
        <div className="dates">
          <h3 className="date_of_shoot">
            Date of shoot:
            <span className="date">
              {' '}
              {APIData?.shoot_data && convertDate(APIData?.shoot_data)}{' '}
            </span>
          </h3>
          <h3 className="delivery_date">
            <img alt="clock" id="clock" src={Clock} />
            Delivery date:
            <span className="date">
              {' '}
              {APIData?.deliver_date && convertDate(APIData?.deliver_date)}
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
};
export default AddressBar;
