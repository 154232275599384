import React, {useState} from 'react'
import "./style.scss"
const CopyLinkFromIframe = ({iframe}) => {

    const [isCopy, setIsCopy] = useState(false);

    const copyLink = (iframe) => {
        setIsCopy(true);
        const src = iframe.split(/src=[src=",src='](.*?)[",']/gm);
        navigator.clipboard.writeText(src[1]);
        setTimeout(() => setIsCopy(false), 1000);
    };

    return (
        <div className="btn_wrapper copied_link_btn">
            <button
                className={
                    isCopy ? "copied_btn copy_component_first_btn"
                        : "download_btn copy_component"
                }
                onClick={() => copyLink(iframe)}
            >
                <div className="liner_border"></div>
                {isCopy ? (
                    <span> Copied </span>
                ) : (
                    <span> Copy link </span>
                )}
            </button>
        </div>
    );
};

export default CopyLinkFromIframe;
