const getUrlFromIframe = (iframe) => {
    
    if (!iframe) {
        return;
    }
    if (!iframe.includes('iframe')) {
        return;
    }
    
    const splittingArrayIntoFewString = iframe.split(" ");
    const findingSrcUrl = splittingArrayIntoFewString.find(el =>
        el.includes("src")
    );
    const lengthOfSrcString = findingSrcUrl.replace('src', '').replace('=', '').replaceAll('"', '').replaceAll("'", '').trim();
    return lengthOfSrcString;
};

export default getUrlFromIframe;
