import BaseRequestService from "./base-request-service";

class Auth extends BaseRequestService {
    logIn = (data) => this.postRequest('/api/auth/login', data)
    signUp = (data) => this.postRequest('/api/auth/signup', data)
    getUser = () => this.getRequest('/api/auth/user')
    signInFacebook = (data) => this.postRequest('/api/auth/facebook/login', data)
    signInGoogle = (data) => this.postRequest('/api/auth/google/login', data)
    logout = () => this.getRequest('/api/auth/logout')
}

export default new Auth()
