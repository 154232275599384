export const downloadFile = (urlArray) => {
  let interrval;
  const myFunc = () => {
    const url = urlArray.pop();
    const link = document.createElement('a');
    link.style.display = 'none';
    // eslint-disable-next-line no-unused-expressions
    (link.href = url), (link.download = `Download`);
    document.body.appendChild(link);
    link.click((event) => event.preventDefault());
    document.body.removeChild(link);
    link.remove();
    if (urlArray.length === 0) {
      clearInterval(interrval);
    }
  };
  interrval = setInterval(() => {
    myFunc();
  }, 1000);
};
