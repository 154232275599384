import BaseRequestService from './BaseRequestService';

class FaqPageService extends BaseRequestService {
  getContent = () => {
    return this.getRequest('/api/faq/content');
  };

  getQuestions = () => {
    return this.getRequest('/api/faq/list');
  };
}

export default new FaqPageService();
