import BaseRequestService from "./base-request-service";

class HomePageService extends BaseRequestService {
    getAllServices = () => {
        return this.getRequest('/api/services');
    }
    getServiceFilters = (id) => {
        return this.getRequest('/api/services/categories/' + id );
    }
    getServicesById = (id) => {
        return this.getRequest('/api/services/service-media/' + id );
    }

    getAllServiceCategories = () => {
        return this.getRequest('/api/services/all-categories')
    }
    getServiceDetails = (id) => {
        return this.getRequest('/api/services/content/' + id );
    }

    getServiceDetailsBySlug = (slug) => {
        return this.getRequest('/api/services/content/slug/' + slug );
    }

    getServiceWorks = (id, data) => {
        return this.getRequest('/api/services/works/' + id, data );
    }

    getSelectedService = () => {
        return this.getRequest('/api/home/services');
    }
    getDiscoverMoreData = () => {
        return this.getRequest('/api/home/discovered');
    }
    getTestimonialReviews = () => {
        return this.getRequest('/api/home/testimonials')
    }
    getPricingData = (query) => {
        return this.getRequest('/pricing/get', query)
    }
    getPricingAndAddServicesTextData = () => {
        return this.getRequest('/api/pricing/content')
    }
    getServiceList = () => {
        return this.getRequest('/api/pricing/services')
    }

    getTeamList = () => {
        return this.getRequest('/api/teams')
    }
}

export default new HomePageService()
