import React, { useEffect, useRef, memo } from 'react';
import { useSelector } from 'react-redux';
import { useService } from '../../context';

const OutsideClick = ({
  children,
  action,
  stopPropagation,
  className = '',
  mousedown,
  style,
  ids,
  id,
}) => {
  const wrap = useRef();
  const { selectedItems, setSelectedItems } = useService();
  const bag = useSelector((store) => store.bag);

  const handleClick = (e) => {
    if (wrap.current && !wrap.current.contains(e.target)) {
      if (stopPropagation) {
        e.stopPropagation();
      }
      action && action(e);
    }
  };

  const handleRemoveFromBag = (id, type) => {
    if (type === 'package') {
      setSelectedItems({
        selectedPackages: [],
        selectedServices: bag.selectedServices,
      });
    } else {
      const index = selectedItems.selectedServices.findIndex(
        (el) => el.id === id
      );
      setSelectedItems({
        selectedPackages: bag.selectedPackages,
        selectedServices: [
          ...selectedItems.selectedServices.slice(0, index),
          ...selectedItems.selectedServices.slice(index + 1),
        ],
      });
    }
  };

  useEffect(() => {
    document.addEventListener(mousedown ? 'mousedown' : 'click', handleClick);
    return () => {
      document.removeEventListener(
        mousedown ? 'mousedown' : 'click',
        handleClick
      );
    };
  }, [wrap]);

  return (
    <>
      <div className={`home_page_redesign_additional_services_remove_wrapper`}>
        <div
          onClick={() =>
            handleRemoveFromBag(bag?.selectedPackages?.[0]?.id, 'package')
          }
          className={`home_page_redesign_additional_services_remove ${
            ids?.includes(id)
              ? 'home_page_redesign_additional_services_remove_includes'
              : ''
          }`}
        >
          Remove plan
        </div>
      </div>
      <div ref={wrap} style={style} className={className}>
        {children}
      </div>
    </>
  );
};

export default memo(OutsideClick);
