import React from 'react';
import './App.css';
import 'scss/main.scss';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'react-toastify/dist/ReactToastify.css';

import * as serviceWorker from './pages/download-zone/serviceWorker';

import { store } from './store';
import Router from './router';
import Context from 'pages/download-zone/context';

function App() {
  return (
    <Provider store={store}>
      <Context>
        <div className="App">
          <Router />
        </div>
        <ToastContainer />
      </Context>
    </Provider>
  );
}

serviceWorker.unregister();

export default App;
