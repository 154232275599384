import config from '../config';
import Axios from 'axios';

class Api {
  headers = () => {
    return {
      'Accept-Language': localStorage.getItem('lang') || 'hy',
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      authorization: localStorage.getItem('token') || '',
    };
  };

  getRequest = async (uri, query) => {
    let queryUrl = new URL(`${config.apiBaseUrl}${uri}`);
    if (query) {
      for (let property in query) {
        if (Array.isArray(query[property])) {
          const arr = query[property];
          delete query[property];
          arr.forEach((element, key) => {
            query[`${property}[${key}]`] = element;
          });
        }
      }
      queryUrl.search = new URLSearchParams(query);
    }
    try {
      const res = await fetch(queryUrl, {
        method: 'GET',
        headers: this.headers(),
      });
      if (!res.ok) {
        return res;
      }
      return res.json();
    } catch (error) {
      console.log(error);
    }
  };

  postRequest = async (uri, params) => {
    const url = `${config.apiBaseUrl}${uri}`;
    const res = await Axios({
      method: 'POST',
      url: url,
      headers: this.headers(),
      data: params,
    })
      .then((res) => res.data)
      .catch((e) => e.response.data);

    return res;
  };

  deleteRequest = async (uri, id) => {
    const url = `${config.apiBaseUrl}${uri}`;
    const res = await fetch(url, {
      method: 'DELETE',
      headers: this.headers(),
      body: JSON.stringify(id),
    });
    return res.json();
  };

  editRequest = async (uri, data) => {
    const url = `${config.apiBaseUrl}${uri}`;
    const res = await fetch(url, {
      method: 'PUT',
      headers: this.headers(),
      body: JSON.stringify(data),
    });
    return res.json();
  };
}

export default Api;
