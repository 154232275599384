import React, { useEffect, useState } from 'react';
import { Faq } from './Faq';
import FaqPageService from '../services/FaqPageService';
import './style.scss';

export const DownloadFaq = () => {
  const [content, setContent] = useState({});

  useEffect(() => {
    FaqPageService.getContent().then((res) => {
      setContent(res.data.items);
    });
  }, []);

  return (
    <div className="download-faq" id="faq_download">
      <div className="text-section">
        <h2>{content.title}</h2>
        <span>{content.sub_title}</span>
      </div>
      <Faq />
    </div>
  );
};
