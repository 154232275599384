import React from 'react';

const DownloadFilesForm = () => {

    return (
        <form id="downloadForm" action="/downloadZip/PropertyVision-files.zip" method="POST" style={{display: 'none'}}>
            <input id="downloadFiles" type="text" name="url" required />
        </form>
    )
}

export default DownloadFilesForm;
