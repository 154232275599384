import React from 'react';
import './style.scss';
import Loading from '../photo-loading/Loading';

//Create constants for the SVG because when we use import we sometimes get icons after we start loading.
const loading_done = (
    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.97926 7.63513C4.48054 8.12162 3.67132 8.12162 3.17284 7.63513L0.37404 4.90491C-0.12468 4.41864 -0.12468 3.62926 0.37404 3.14299C0.872519 2.65649 1.68174 2.65649 2.18046 3.14299L3.84799 4.76943C3.97387 4.89199 4.17822 4.89199 4.30435 4.76943L8.81954 0.364874C9.31802 -0.121625 10.1272 -0.121625 10.626 0.364874C10.8655 0.598497 11 0.915473 11 1.24583C11 1.57619 10.8655 1.89317 10.626 2.12679L4.97926 7.63513Z"
            fill="white"/>
    </svg>
);

const DownloadProgressElement = ({name, folderName, progress}) => {
    return (
        <div className='download_progress_element_wrapper'>
            <div className='download_progress_element'>
                <div className='download_progress_element_name'>
                    {
                        `${folderName ? folderName : ''} ${name}`
                    }
                </div>
                <div className='download_progress_element_loading'>
                    {
                        progress === 100 ?
                            (<div className='download_progress_element_loading_done'>
                                {loading_done}
                            </div>) : <Loading progress={progress}/>
                    }
                </div>
            </div>
        </div>
    );
};

export default DownloadProgressElement;
