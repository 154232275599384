export const addressActionCreator = (address, unit_number) => {
    return {
        type: 'SET_ADDRESS', payload: {address, unit_number}
    };
};

export const downloadFileTypeActionCreator = (fileType) => {
    return {
        type: 'SET_DOWNLOAD_FILE_TYPE', payload: fileType
    };
};

export const setServicesActionCreator = (data) => {
    return {
        type: 'SET_SERVICES', payload: data
    };
};

export const modalOpenActionCreator = (data) => {
    return {
        type: 'OPEN_CLOSE_MODAL', payload: data
    };
};
