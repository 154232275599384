import React from 'react';
import './style.scss';

const PhotoSelectButton = ({isSelectedMode, setIsSelectedMode}) => {
    return (
        <div className='photo_select_button_for_mobile_wrapper'>
            {
                !isSelectedMode
                    ? <div className='photo_select_button_for_mobile_select'
                           onClick={() => setIsSelectedMode(!isSelectedMode)}>Select</div>
                    : <div className='photo_select_button_for_mobile_cancel'
                           onClick={() => setIsSelectedMode(!isSelectedMode)}>Cancel</div>
            }
        </div>
    )
};

export default PhotoSelectButton;
