import React from 'react';
import { DownloadButton } from '../DownloadButton/DownloadButton';
import Buttons from '../Matterports/Buttons';
import '../video_and_matterport/matterport.styles.scss';
import '../matterport/style.scss';
import { getUrlFromIframe } from '../../helpers/getUrlFrameIframe';
import { CopyLinkFromIframe } from '../CopyLinkFromIframe';

export const Videos = ({
  video,
  title,
  textOfVideos,
  downloadVideoButton,
  APIData,
  element,
  copiedUrl,
  iframe,
}) => {
  return (
    <>
      <div className="video-container">
        <div className="grid-item">
          <div className="videos">
            <iframe
              src={getUrlFromIframe(video)}
              max-width="430px"
              max-height="236px"
              className="video"
              allowFullScreen={true}
            />
          </div>
          <h1 className="video_title">{title}</h1>
          <p className="description">{textOfVideos}</p>
          <div className="download_btn_wrapper_brand">
            {!!APIData?.matterport?.length ? (
              <Buttons element={element} el={copiedUrl} />
            ) : (
              <div className="video_button_wrapper">
                <DownloadButton
                  videoLink={getUrlFromIframe(downloadVideoButton)}
                  buttonName="DOWNLOAD VIDEO"
                />
                <CopyLinkFromIframe iframe={iframe} />
              </div>
            )}
          </div>
          <div className="multi-liner"></div>
        </div>
      </div>
    </>
  );
};
