import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import downloadImagesAsZip from './files-download-zip';

export const ContextStore = createContext();

const Context = ({ children }) => {
  const bag = useSelector((st) => st.bag);
  const [active, setActive] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [show, setShow] = useState(false);
  const [islightboxopen, setislightboxopen] = useState(false);
  const [lightboxVideo, setLightboxVideo] = useState(null);
  const [isLightBoxVideoOpen, setLightBoxVideoOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState();
  const [sqft, setSqft] = useState(localStorage.getItem('sqft') || '0');
  const [additionalServices, setAdditionalServices] = useState([]);
  const [packages, setPackages] = useState([]);
  const [prevPackage, setPrevPackage] = useState();
  const [addSlider, setAddSlider] = useState();
  const user = useSelector((store) => store.user);
  const [selectedItems, setSelectedItems] = useState(
    JSON.parse(localStorage.getItem('bag')) || {
      selectedServices: bag?.selectedServices,
      selectedPackages: bag?.selectedPackages,
      sqft: null,
    }
  );
  const dispatch = useDispatch();
  const firstUpdate = useRef(true);

  const addBag = () => {
    localStorage.setItem('bag', JSON.stringify(selectedItems));
    dispatch({
      type: 'BAG',
      data: selectedItems,
    });
  };

  useEffect(() => {
    if (!!packages?.length && !!additionalServices?.length) {
      setSelectedItems((st) => {
        if (!!st?.selectedPackages?.length || !!st?.selectedServices?.length) {
          const data = {
            selectedPackages: st?.selectedPackages?.map((el) =>
              packages.find((e) => e.id === el.id)
            ),
            selectedServices: st?.selectedServices?.map((el) =>
              additionalServices.find((e) => e.id === el.id)
            ),
            sqft: selectedItems.sqft,
          };
          localStorage.setItem('bag', JSON.stringify(data));
          dispatch({
            type: 'BAG',
            data: data,
          });
          return data;
        } else {
          return st;
        }
      });
    }
  }, [packages, additionalServices]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    addBag();
  }, [selectedItems]);

  const openCart = () => {
    setIsCartOpen(!isCartOpen);
  };

  const openLightBox = () => {
    islightboxopen ? setislightboxopen(false) : setislightboxopen(true);
    islightboxopen
      ? document.body?.classList.remove('no_scroll')
      : document.body?.classList.add('no_scroll');
  };

  const placeOrderHandler = () => {
    if (!!user) {
      const newSelectedItems = { ...selectedItems };
      const price = {
        sqft: null,
        packages: {},
        service: [],
        discount: null,
        subtotal: null,
        total_cost: null,
      };

      price.subtotal = totalwithoutDiscount();
      price.total_cost = totalWithDiscount();
      price.sqft = Number(sqft);
      price.discount = totalDiscount();
      price.packages = newSelectedItems?.selectedPackages?.shift() || {};
      price.service = newSelectedItems?.selectedServices || [];
      const redirectToken = localStorage.getItem('redirect_token');

      localStorage.setItem('selected_items', JSON.stringify(price));
      localStorage.removeItem('bag');
      window.location.href = `/tours/create?auth_token=${redirectToken}`;
    }
  };
  const download = (files) => {
    let filesArray = [];
    files = JSON.parse(files);
    files.map((item) => {
      filesArray.push(item);
    });
    downloadImagesAsZip.execute(
      filesArray,
      'Property Vision HQ images',
      function () {
        //TODO: Stop loader here.
      }
    );
  };

  const totalwithoutDiscount = () => {
    const overallItems = [...bag?.selectedPackages, ...bag?.selectedServices];
    return overallItems.reduce(
      (sum, currentItem) => sum + currentItem.price,
      0
    );
  };

  const totalDiscount = () => {
    if (bag.selectedPackages.length <= 0) return 0;
    const overallItems = [...bag?.selectedPackages, ...bag?.selectedServices];
    const discount =
      Math.round(
        (totalwithoutDiscount() -
          overallItems.reduce(
            (sum, el) =>
              sum +
              (el.price -
                (el.price * parseInt(el.discount_percent || 0)) / 100),
            0
          )) *
          100
      ) / 100;
    const splitted = discount.toString().split('.');
    splitted[1] = splitted[1]?.padEnd(2, '0');
    return Number(splitted.join('.'));
  };

  const totalWithDiscount = () => {
    const all = totalwithoutDiscount() - totalDiscount();
    const total = Math.round((all + (all * 13) / 100) * 100) / 100;
    const splitted = total.toString().split('.');
    splitted[1] = splitted[1]?.padEnd(2, '0');
    return Number(splitted.join('.'));
  };

  return (
    <ContextStore.Provider
      value={{
        placeOrderHandler,
        totalwithoutDiscount,
        totalDiscount,
        totalWithDiscount,
        addBag,
        selectedService,
        setSelectedService,
        openCart,
        isCartOpen,
        islightboxopen,
        openLightBox,
        lightboxVideo,
        setLightboxVideo,
        selectedServiceId,
        setSelectedServiceId,
        isLightBoxVideoOpen,
        setLightBoxVideoOpen,
        sqft,
        setSqft,
        prevPackage,
        setPrevPackage,
        additionalServices,
        setAdditionalServices,
        packages,
        setPackages,
        selectedItems,
        setSelectedItems,
        addSlider,
        setAddSlider,
        download,
        active,
        setActive,
        show,
        setShow,
      }}
    >
      {children}
    </ContextStore.Provider>
  );
};

export default Context;

export const useService = () => useContext(ContextStore);
