import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useService } from '../context';
import { scroll } from '../scroll';

import HomePageService from '../services/home-page-service';
import SignInDropdown from '../components/sign-in-dropdown/sign_in_dropdown';
import ApiAuth from '../services/auth-service';
import config from '../config';

import humburgerwhite from '../images/humburgerwhite.svg';
import humburgerblack from '../images/humburgerblack.svg';
import vector1 from '../images/vector1.svg';
import headerlogo from '../images/headerlogo.svg';
import blacklogo from '../images/blacklogo.svg';
import newphone from '../images/newphone.svg';
import blackphone from '../images/blackphone.svg';
import newcart from '../images/newcart.svg';
import blackcart from '../images/blackcart.svg';
import userIcon from '../images/userIcon.svg';
import userIconWhite from '../images/userIconWhite.svg';
import whiteCart from '../images/whiteCart.svg';
import arrow_down from '../images/arrow_down.svg';
import arrow_down_white from '../images/arrow_down_white.svg';
import head_phone_icon_black from '../images/head_phone_icon_black.svg';
import head_phone_icon_white from '../images/head_phone_icon_white.svg';
import head_phone_icon_hover_black from '../images/head_phone_icon_hover_black.svg';
import head_phone_icon_hover_white from '../images/head_phone_icon_hover_white.svg';
import user_logged_in_default from '../images/user_logged_in_default.svg';

import '../home.style.scss';
import { withRouter } from '../custom_hooks/withRouter';

function HeaderNavigationHome(props) {
  const { router } = props;
  const { location } = router;
  const { pathname } = location;
  const nav = useRef(null);
  const { slug } = useParams();
  const [top, setTop] = useState(window.scrollY > 0);
  const { show, setShow } = useService(false);
  const bag = useSelector((store) => store.bag);
  const { openCart, setSelectedServiceId } = useService();
  const [serviceOpen, setServiceOpen] = useState(false);
  const [hide, sethide] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bagNotification, setBagNotification] = useState(false);
  const [timer, setTimer] = useState();
  const [slugContent, setSlugContent] = useState({});
  const url = config?.apiBaseUrl;
  const imageUrl = config.imagesBaseUrl;
  const openCloseModal = useSelector((state) => state.openCloseModal);
  const urlOfServices = window.location.origin + '/services/' + slug;
  const userInfo = useSelector((st) => st.user);
  const [rootElement, setRootElement] = useState(null);
  const headerNavigation = document.querySelector('.header_navigation');

  const services = useSelector((state) => state.services);

  const serviceOpenHandler = (el) => {
    window.open(window.location.origin + '/services/' + el.content.slug);
  };

  const handleOpenMenu = () => {
    if (headerNavigation) {
      headerNavigation?.classList.add('new_header_navigation_opened_menu');
    }
    if (rootElement) {
      rootElement.style.overflow = 'hidden';
      rootElement.style.height = '100vh';
    }
    setShow(true);
  };

  const handleCloseMenu = () => {
    if (headerNavigation) {
      headerNavigation?.classList.remove('new_header_navigation_opened_menu');
    }
    if (rootElement) {
      rootElement.style.overflow = 'auto';
      rootElement.style.height = 'auto';
    }
    setShow(false);
  };

  useEffect(() => {
    setRootElement(document.getElementById('root'));
  }, []);

  useEffect(() => {
    if (
      (bag?.selectedServices && bag?.selectedServices?.length) ||
      (bag?.selectedPackages && bag.selectedPackages?.length)
    ) {
      setBagNotification(true);

      setTimer((st) => {
        if (st) {
          clearInterval(st);
        }

        return setTimeout(() => {
          setBagNotification(false);
        }, 3000);
      });
    } else {
      setBagNotification(false);
    }
  }, [bag]);

  const user = useSelector((store) => store.user);
  const getAuthToken = () => {
    return localStorage.getItem('redirect_token');
  };

  useEffect(() => {
    const el = document.getElementById('fixed_video');
    const navScroll = () => {
      if (window.scrollY > 0) {
        setTop(true);
      } else if (nav?.current && nav.current.classList[0] === 'pricing') {
        setTop(false);
      }
      if (window.scrollY > window.innerHeight * 2) {
        el && el.classList.add('hide');
      } else {
        el && el.classList.remove('hide');
      }
    };

    window.addEventListener('scroll', navScroll);
    return () => {
      window.removeEventListener('scroll', navScroll);
    };
  }, []);

  useEffect(() => {
    document.querySelector('.App > div')?.classList.remove('hide');
    setTimeout(() => {
      document.querySelector('.App > div')?.classList.add('hide');
    }, 0);
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  useEffect(() => {
    HomePageService.getServiceDetailsBySlug(slug).then((res) => {
      setSlugContent(res.data.item);
    });
  }, [slug]);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        sethide(false);
      }, 300);
    }
  }, [hide]);

  const selectService = (service) => {
    setSelectedServiceId(service?.id);
    setServiceOpen(false);
    setShow(false);

    if (pathname === '/' && setSelectedServiceId) {
      const el = document.getElementById('services');
      const top = el?.getBoundingClientRect().top + window.pageYOffset - 80;
      scroll(top);
    } else {
      navigate('/');
      setTimeout(() => {
        const el = document.getElementById('services');
        const top = el?.getBoundingClientRect().top + window.pageYOffset - 80;
        scroll(top);
      }, 1000);
    }
  };
  const scrollService = () => {
    if (window.innerWidth < 1101) {
      setServiceOpen(!serviceOpen);
    }
  };

  const rowTemplate = (name, price, key) => {
    return (
      <tr key={key}>
        <td className="title">{name}</td>
        <td className="price">${price}</td>
      </tr>
    );
  };

  const logOut = () => {
    ApiAuth.logout().then((res) => {
      if (res.success) {
        localStorage.removeItem('token');
        localStorage.removeItem('redirect_token');

        dispatch({
          type: 'USER',
          data: null,
        });

        setTimeout(() => document.getElementById('logout-form').submit(), 100);
      }
    });
  };

  const countAddedServices = () => {
    return bag?.selectedServices?.length + bag?.selectedPackages?.length;
  };

  useEffect(() => {
    if (rootElement) {
      rootElement.style.overflow = 'inherit';
    }
  }, [rootElement]);

  return (
    <nav
      ref={nav}
      className={`${
        top || show || pathname.includes('/about-us') ? 'pricing' : ''
      } ${location.pathname === '/pricing' ? 'pricing' : ''} ${
        openCloseModal && 'header_navigation_modal_mode'
      } navbar navbar-expand-lg header_navigation`}
    >
      <Link to="/" className="navbar-brand">
        <img alt="headerlogo" src={headerlogo} className="white_logo" />
        <img alt="blacklogo" src={blacklogo} className="black_logo" />
      </Link>

      <div className="navigation_cart_container navigation_cart_container_mobile">
        <div className="nav_phone_number_container">
          <img alt="newphone" src={newphone} className="white_phone" />
          <img alt="blackphone" src={blackphone} className="black_phone" />
        </div>

        {!user && (
          <div className="sign-in-wrapper">
            <a href="/signin">
              <img
                src={userIcon}
                className="mobile-signin-black"
                alt="sign in"
                width="21"
                height="21"
              />
              <img
                src={userIconWhite}
                className="mobile-signin-white"
                alt="sign in"
                width="21"
                height="21"
              />
            </a>
          </div>
        )}
        <div className="nav_cart_container" onClick={openCart}>
          <img alt="newcart" src={newcart} className="cart_white" />
          <img alt="blackcart" src={blackcart} className="cart_black" />
          <div>
            <a className="cart-text">{`Cart (${countAddedServices()})`}</a>
          </div>
        </div>
        <img
          alt="humburgerwhite"
          src={humburgerwhite}
          className={`humburgermenuwhite ${show && 'disable-hamburger-icon'} `}
          onClick={handleOpenMenu}
        />
        {show ? (
          <img
            alt="closing button"
            src={vector1}
            onClick={handleCloseMenu}
            className="closing-button"
          />
        ) : (
          <img
            alt="humburgerblack"
            src={humburgerblack}
            className="humburgermenublack"
            onClick={handleOpenMenu}
          />
        )}
      </div>

      <div
        className={`collapse navbar-collapse nav_list_container collapse show ${
          show ? 'show_menu' : ''
        } `}
        id="main_navigation"
      >
        <ul
          className={`navbar-nav ${!user && 'new_not_login'} ${
            !!user &&
            (user.role === 'Admin' ||
              user.role === 'Deliveryman' ||
              user.role === 'Photographer')
              ? 'more'
              : ''
          }`}
        >
          {!!user &&
            (user.role === 'Admin' ||
              user.role === 'Deliveryman' ||
              user.role === 'Photographer') && (
              <li className="nav-item">
                <Link
                  to="/dashboard"
                  onClick={() => {
                    setShow(false);
                    setServiceOpen(false);
                    window.location.href = `/dashboard?auth_token=${getAuthToken()}`;
                  }}
                  className="nav-link navigation_links"
                >
                  DASHBOARD
                </Link>
              </li>
            )}

          <li className="nav-item">
            <a href="/our-work" className="nav-link navigation_links">
              OUR WORK
            </a>
          </li>

          <li className="nav-item">
            <span
              onClick={scrollService}
              className={`nav-link navigation_links ${hide ? 'hide' : ''} ${
                serviceOpen ? 'open' : ''
              }`}
              to="/"
            >
              <svg
                width="9"
                height="5"
                viewBox="0 0 9 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.31373 5L0 0.686275L0.686275 0L4.31373 3.61111L7.92484 0L8.62745 0.686275L4.31373 5Z"
                  fill="white"
                />
              </svg>
              SERVICES
              <img
                src={arrow_down}
                alt="Arrow Down"
                className="services_arrow_down"
              />
              <img
                src={arrow_down_white}
                alt="Arrow Down"
                className="services_arrow_down_white"
              />
              <div className="short-highlighted-border_wrapper">
                <div className="short-highlighted-border"></div>
              </div>
              <div className=" pop services-pop">
                <div className="services_pop_bottom"></div>
                {services?.map(
                  (el, index) =>
                    !!el.visible && (
                      <Link to={'/services/' + el.content.slug} key={index}>
                        <div key={index} className="services-dropdown">
                          <img
                            src={imageUrl + el.active_icon}
                            className="service-icons"
                            alt={el?.title}
                          />
                          <span>{el?.title}</span>
                        </div>
                      </Link>
                    )
                )}
              </div>
            </span>
          </li>
          {!!user && (user.role === 'Admin' || user.role === 'Deliveryman') && (
            <li className="nav-item">
              <span
                onClick={() => setUsersOpen((value) => !value)}
                className={`nav-link navigation_links navigation_links_users ${
                  usersOpen ? 'open' : ''
                }`}
                to="/"
              >
                USERS
                <svg
                  width="9"
                  height="5"
                  viewBox="0 0 9 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.31373 5L0 0.686275L0.686275 0L4.31373 3.61111L7.92484 0L8.62745 0.686275L4.31373 5Z"
                    fill="white"
                  />
                </svg>
                <div className="pop user_pop">
                  <span
                    onClick={() =>
                      (window.location.href = `/users/client?auth_token=${getAuthToken()}`)
                    }
                  >
                    Clients
                  </span>
                  <span
                    onClick={() =>
                      (window.location.href = `/users/photographer?auth_token=${getAuthToken()}`)
                    }
                  >
                    Photographers
                  </span>
                  <span
                    onClick={() =>
                      (window.location.href = `/users/editor?auth_token=${getAuthToken()}`)
                    }
                  >
                    Video Editors
                  </span>
                  <span
                    onClick={() =>
                      (window.location.href = `/users/photoeditor?auth_token=${getAuthToken()}`)
                    }
                  >
                    Photo Editors
                  </span>
                  <span
                    onClick={() =>
                      (window.location.href = `/users/Deliveryman?auth_token=${getAuthToken()}`)
                    }
                  >
                    Delivery Personnel
                  </span>
                </div>
              </span>
            </li>
          )}

          {!!user && (
            <li className="nav-item">
              <Link
                to="/tours"
                onClick={() => {
                  setShow(false);
                  setServiceOpen(false);
                  window.location.href = `/tours?auth_token=${getAuthToken()}`;
                }}
                className="nav-link navigation_links"
              >
                {user.role === 'Client' ? 'TOURS' : 'Tours'}
              </Link>
            </li>
          )}

          <li className="nav-item">
            <a href="/pricing" className="nav-link navigation_links">
              PRICING
            </a>
          </li>
          {user?.roles?.[0]?.name !== 'Admin' && (
            <li className="nav-item">
              <a href="/blog" className="nav-link navigation_links">
                Blog
              </a>
            </li>
          )}
          {/*<li className="nav-item">*/}
          {/*    <a href='/about-us' className="nav-link navigation_links">ABOUT US</a>*/}
          {/*</li>*/}
          {user?.roles?.[0]?.name !== 'Admin' && (
            <li className="nav-item">
              <a href="/help-and-tips" className="nav-link navigation_links">
                HELP & TIPS
              </a>
            </li>
          )}
        </ul>
      </div>

      <div className="navigation_cart_container">
        <a href="tel:(905) 766 9009">
          <div className="head_phone_icon">
            <img
              src={head_phone_icon_black}
              alt="Phone"
              className="head_phone_icon_black"
            />
            <img
              src={head_phone_icon_white}
              alt="Phone"
              className="head_phone_icon_white"
            />
            <img
              src={head_phone_icon_hover_black}
              alt="Phone"
              className="head_phone_icon_hover_black"
            />
            <img
              src={head_phone_icon_hover_white}
              alt="Phone"
              className="head_phone_icon_hover_white"
            />
            <div className="head_phone_number">905 766 9009</div>
          </div>
        </a>

        <div className="order-tour">
          <div className="order-btn-wrapper">
            <div className="order-tour-btn">
              <a
                href={user ? '/tours/create' : '/signin'}
                onClick={() => {
                  setShow(false);
                  setServiceOpen(false);
                }}
                className="order-link navigation_links new_home_order_link"
              >
                Order Tour
              </a>
            </div>
          </div>
        </div>

        <div
          className="nav_cart_container new_home_nav_cart_container"
          onClick={openCart}
        >
          {!!countAddedServices() && (
            <div className="new_home_nav_cart_container_dot">
              <div className="new_home_cart_text"></div>
            </div>
          )}
          <img alt="cart" src={whiteCart} className="white_cart" />
          <img alt="cart" src={blackcart} className="cart_black" />
          <div className="bag-navigation">
            {bagNotification && (
              <div className="auth-bag active">
                <div className="auth-triangle"></div>
                <div className="bag-content">
                  <table className="bag-table">
                    {bag.selectedPackages.map((item) =>
                      rowTemplate(item.title, item.price, item.id)
                    )}
                    {bag.selectedServices.map((item) =>
                      rowTemplate(item.title, item.price, item.id)
                    )}
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="humburgermenuwhite_wrapper">
          <img
            alt="humburgerwhite"
            src={humburgerwhite}
            className="humburgermenuwhite"
            onClick={handleOpenMenu}
          />
        </div>
        {show ? (
          <div className="closing_button_wtapper">
            <img
              alt="closing button"
              src={vector1}
              onClick={handleCloseMenu}
              className="closing-button"
            />
          </div>
        ) : (
          <div className="humburgerblack_wrapper">
            <img
              alt="humburgerblack"
              src={humburgerblack}
              className="humburgermenublack"
              onClick={handleOpenMenu}
            />
          </div>
        )}
        {!user ? (
          <div
            className="new_home_user_signin_wrapper"
            onClick={handleCloseMenu}
          >
            <div className="user-signin">
              <div className="new_home_sign_in_icon_wrapper">
                <div className="sign-up-button new_home_sign_up_button">
                  <div className="new_home_sign_in_line"></div>
                  <div className="new_home_sign_in_line"></div>
                  <div className="new_home_sign_in_line"></div>
                </div>
                <div className="new_home_user_icon_wrapper">
                  <img
                    src={userIcon}
                    className="userIcon-black"
                    alt="user sign in icon"
                  />
                  <img
                    src={userIcon}
                    className="userIcon-white"
                    alt="user sign in icon"
                  />
                </div>
              </div>
              {!userInfo && (
                <div className="sign-in-dropdown">
                  <SignInDropdown />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="person_id" onClick={handleCloseMenu}>
            {user?.id && (
              <div className="user-signin">
                <div className="new_home_sign_in_icon_wrapper">
                  <div className="sign-up-button new_home_sign_up_button">
                    <div className="new_home_sign_in_line"></div>
                    <div className="new_home_sign_in_line"></div>
                    <div className="new_home_sign_in_line"></div>
                  </div>
                  <div className="new_home_user_icon_wrapper">
                    <img
                      src={user?.image ? user?.image : user_logged_in_default}
                      className="userIcon-black"
                      alt="user sign in icon"
                    />
                    <img
                      src={user?.image ? user?.image : user_logged_in_default}
                      className="userIcon-white"
                      alt="user sign in icon"
                    />
                  </div>
                </div>
                {!userInfo && (
                  <div className="sign-in-dropdown">
                    <SignInDropdown />
                  </div>
                )}
              </div>
            )}
            <span className="pop">
              <div className="popMenu">
                <a href={`/user/${user?.id}?auth_token=${getAuthToken()}`}>
                  <span className="my-account-span">My Account</span>
                </a>
                <a
                  href={`/user/${
                    user?.id
                  }/settings?auth_token=${getAuthToken()}`}
                >
                  <span className="settings-span">Settings</span>
                </a>
                <div onClick={logOut}>
                  <span className="sign-out-span">Sign out</span>
                </div>
              </div>
            </span>
          </div>
        )}
      </div>
    </nav>
  );
}

export default withRouter(HeaderNavigationHome);
