import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as Scroll from 'react-scroll';

import headerlogo from '../images/headerlogo.svg';
import property_vision_website_white_logo from '../images/Property_vision_website_white_logo.svg';
import property_vision_website_black_logo from '../images/Property_vision_website_black_logo.svg';
import blacklogo from '../images/blacklogo.svg';
import newphone from '../images/newphone.svg';
import blackphone from '../images/blackphone.svg';
import newcart from '../images/newcart.svg';
import blackcart from '../images/blackcart.svg';
import './style.scss';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import humburgerwhite from '../images/humburgerwhite.svg';

import vector1 from '../images/vector1.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useService } from '../context';

import userIcon from '../images/user.svg';
import settings from '../images/settings.svg';
import logOutImg from '../images/logOut.svg';
import ApiAuth from '../services/auth-service';
import config from '../config';

import Popup from '../components/popup/Popup';
import selectService from '../global/services-scrolling';
import '../animate.css/animate.min.css';
import { withRouter } from '../custom_hooks/withRouter';

function HeaderNavigation(props) {
  const { router } = props;
  const { location } = router;
  const { pathname } = location;
  const url = config?.tempUrl;
  const nav = useRef();
  const { slug, id } = useParams();
  const [show, setShow] = useState(false);
  const [top, setTop] = useState(window.scrollY);
  //   const bag = useSelector((store) => store.bag);
  const selectedPackages = useSelector((state) => state.tours.selectedPackages);
  const selectedServices = useSelector((state) => state.tours.selectedServices);
  const { openCart, setSelectedServiceId } = useService();
  const [serviceOpen, setServiceOpen] = useState(false);
  const [hide, sethide] = useState(false);
  const [usersOpen, setUsersOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bagNotification, setBagNotification] = useState(false);
  const [timer, setTimer] = useState();
  const [currentUrl, setCurrentUrl] = useState(false);
  const scroll = Scroll.animateScroll;
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [transitionFromTours, setTransitionFromTours] = useState(false);
  const [page, setPage] = useState('');
  const [isPageMobileWidth, setIsPageMobileWidth] = useState(false);

  const user = useSelector((store) => store.user);
  const services = useSelector((store) => store.services);
  const { tourAddress } = useSelector((store) => store);

  const firstAddress = tourAddress?.address?.split(/[,]/)[0];
  const userTypes = ['Admin', 'Deliveryman', 'Photographer'];

  const getAuthToken = () => {
    return localStorage.getItem('redirect_token');
  };

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      const scrollDistanceFromTop = 400;
      const downloadPage = pathname.includes(`/tour/${id}/download-zone`);
      const stickySideBar = document.getElementById('sticky');

      if (
        top > window.scrollY &&
        window.scrollY > scrollDistanceFromTop &&
        downloadPage
      ) {
        if (stickySideBar) {
          stickySideBar.style.top = '65px';
        }
      } else if (top < window.scrollY && window.scrollY > 0 && downloadPage) {
        if (stickySideBar) {
          stickySideBar.style.top = '0px';
        }
      }
      setTop(window.scrollY);
    },
    [top]
  );

  const handleDownloadInfo = () => {
    setPopupIsOpen(true);
  };

  const scrollService = () => {
    if (window.innerWidth < 992) {
      setServiceOpen(!serviceOpen);
    } else {
      const el = document.getElementById('services');
      const top = el?.getBoundingClientRect().top + window.pageYOffset - 80;
      scroll(top);
      setServiceOpen(false);
      sethide(true);
    }
  };

  const serviceTitles = (title) => {
    const changeToUppercase =
      title[0].toUpperCase() + title.slice(1).toLowerCase();

    return changeToUppercase;
  };

  const logOut = () => {
    ApiAuth.logout().then((res) => {
      if (res.success) {
        localStorage.removeItem('token');
        localStorage.removeItem('redirect_token');

        dispatch({
          type: 'USER',
          data: null,
        });

        setTimeout(() => document.getElementById('logout-form').submit(), 100);
      }
    });
  };

  const nameCapitalized = (username) => {
    const usernameSwitchToAllLowerCase = username.toLowerCase();
    const usernameSwitchToCapitalize = `${usernameSwitchToAllLowerCase
      .charAt(0)
      .toUpperCase()}${usernameSwitchToAllLowerCase.slice(1)}`;

    return usernameSwitchToCapitalize;
  };

  useEffect(() => {
    const arr = pathname.split('/');
    setPage(arr[arr.length - 1]);
  }, [location]);

  useEffect(() => {
    if (
      (selectedServices && selectedServices.length > 0) ||
      (selectedPackages && selectedPackages.length > 0)
    ) {
      setBagNotification(true);

      setTimer((st) => {
        if (st) {
          clearInterval(st);
        }

        return setTimeout(() => {
          setBagNotification(false);
        }, 3000);
      });
    } else {
      setBagNotification(false);
    }
  }, [selectedServices, selectedPackages]);

  useEffect(() => {
    setTop(window.scrollY);
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    if (document.getElementsByTagName('html')) {
      document.getElementsByTagName('html')[0].style.overflow = show
        ? 'hidden'
        : 'unset';
    }
    if (document.getElementById('pricing')) {
      document.getElementById('pricing').style.top = show ? '0px' : 'inherit';
    }
  }, [show]);

  useEffect(() => {
    if (slug && services) {
      const elem = services.find((el) => el.content?.slug === slug);
      if (elem) {
        selectService(
          elem.id,
          'top',
          navigate,
          pathname,
          setSelectedServiceId,
          setServiceOpen,
          setShow
        );
      }
    }
  }, [slug, services]);

  useEffect(() => {
    setCurrentUrl(pathname === `/tour/${id}/download-zone`);
  }, [pathname]);

  useEffect(() => {
    document.querySelector('.App > div')?.classList.remove('hide');
    setTimeout(() => {
      document.querySelector('.App > div')?.classList.add('hide');
    }, 0);
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  useEffect(() => {
    if (hide) {
      setTimeout(() => {
        sethide(false);
      }, 300);
    }
  }, [hide]);

  useEffect(() => {
    if (!location.search) {
      return;
    }
    if (!!services) {
      setTimeout(() => {
        selectService(
          location.search.split('&')[1].split('=')[1],
          'top',
          navigate,
          pathname,
          setSelectedServiceId,
          setServiceOpen,
          setShow,
          setTransitionFromTours
        );
      }, 1000);
    }
  }, [services]);

  useEffect(() => {
    if (window.scrollY === 0) {
      if (document.getElementById('pricing')) {
        document.getElementById('pricing').style.top = '0';
      }
    }
  }, [window.scrollY]);

  useEffect(() => {
    setIsPageMobileWidth(window.innerWidth < 767);
  });

  return (
    <>
      <nav
        ref={nav}
        id="pricing"
        className={`${
          top ? 'pricing' : 'navbar navbar-expand-lg header_navigation'
        } ${
          pathname === '/pricing' ? 'pricing' : ''
        } navbar navbar-expand-lg header_navigation`}
      >
        {isPageMobileWidth && page === 'download-zone' ? (
          top && (
            <div className="download_zone_header_address">
              <div className="download_zone_header_address_location">
                {firstAddress}
              </div>
              <div className="download_zone_header_address_unit_number">
                UNIT {tourAddress?.unit_number}
              </div>
            </div>
          )
        ) : (
          <>
            {page === 'download-zone' ? (
              <div className="navbar-brand">
                <a href="/" target="_blank">
                  <img
                    alt=""
                    src={property_vision_website_white_logo}
                    className="white_logo"
                  />
                </a>
                <Link to="#">
                  {!currentUrl ? (
                    <img
                      alt=""
                      src={property_vision_website_black_logo}
                      className="black_logo"
                      onClick={() => window.open('/')}
                    />
                  ) : (
                    <img
                      alt=""
                      src={property_vision_website_black_logo}
                      className="black_logo"
                      onClick={() =>
                        window.pageYOffset
                          ? scroll.scrollToTop()
                          : window.open('/')
                      }
                    />
                  )}
                </Link>
              </div>
            ) : (
              <div className="navbar-brand">
                <Link to="/">
                  <img alt="" src={headerlogo} className="white_logo" />
                </Link>
                <Link to="#">
                  {!currentUrl ? (
                    <img
                      alt=""
                      src={blacklogo}
                      className="black_logo"
                      onClick={() => navigate('/')}
                    />
                  ) : (
                    <img
                      alt=""
                      src={blacklogo}
                      className="black_logo"
                      onClick={() =>
                        window.pageYOffset
                          ? scroll.scrollToTop()
                          : navigate('/')
                      }
                    />
                  )}
                </Link>
              </div>
            )}
            <div
              className={`collapse navbar-collapse nav_list_container collapse show ${
                show ? 'show_menu' : ''
              } `}
              id="main_navigation"
            >
              <ul
                className={`navbar-nav ${
                  !!user && userTypes.includes(user.role) && 'more'
                }`}
              >
                {page !== 'download-zone' && (
                  <li className="nav-item">
                    <span
                      onClick={scrollService}
                      className={`nav-link navigation_links ${
                        hide ? 'hide' : ''
                      } ${serviceOpen ? 'open' : ''}`}
                      to="/"
                    >
                      SERVICES
                      <svg
                        width="9"
                        height="5"
                        viewBox="0 0 9 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.31373 5L0 0.686275L0.686275 0L4.31373 3.61111L7.92484 0L8.62745 0.686275L4.31373 5Z"
                          fill="white"
                        />
                      </svg>
                      <div className="pop">
                        <div className="date-of-update">
                          <button>Next Day Delivery</button>
                        </div>
                        <div className="items-flex-container">
                          <div className="line-seperator"></div>
                          <div className="items-grid-container">
                            {services?.map(
                              (el) =>
                                !!el.visible && (
                                  <div
                                    className="service-item"
                                    onClick={() => {
                                      pathname === '/'
                                        ? selectService(
                                            el.id,
                                            'top',
                                            navigate,
                                            pathname,
                                            setSelectedServiceId,
                                            setServiceOpen,
                                            setShow
                                          )
                                        : window.open(
                                            `/?tour=true&id=${el.id}`
                                          );
                                    }}
                                  >
                                    <img
                                      src={url + el.active_icon}
                                      className="services_icon"
                                    />
                                    <span className="service-item-titles">
                                      {serviceTitles(el.title)}
                                    </span>
                                  </div>
                                )
                            )}
                          </div>
                        </div>
                      </div>
                    </span>
                  </li>
                )}
                {page !== 'download-zone' && (
                  <li className="nav-item">
                    <a
                      target={`${pathname === '/' ? '' : '_blank'}`}
                      href={user ? '/tours/create' : '/signin'}
                      onClick={() => {
                        setShow(false);
                        setServiceOpen(false);
                      }}
                      className="nav-link navigation_links"
                    >
                      ORDER A TOUR
                    </a>
                  </li>
                )}
                {page !== 'download-zone' && (
                  <li className="nav-item">
                    <a
                      target={`${pathname === '/' ? '' : '_blank'}`}
                      href={user ? '/tours/create' : '/pricing'}
                      onClick={() => {
                        setShow(false);
                        setServiceOpen(false);
                      }}
                      className="nav-link navigation_links"
                    >
                      Pricing
                    </a>
                  </li>
                )}
                {page === 'new_home_page' && (
                  <li className="nav-item">
                    <Scroll.Link
                      to="faq_download"
                      spy={true}
                      smooth={true}
                      duration={500}
                      className="nav-link navigation_links"
                    >
                      FAQ
                    </Scroll.Link>
                  </li>
                )}
                {!user && (
                  <a href="/signin" className="sign_in">
                    Sign In
                  </a>
                )}
              </ul>
              <div className="mobile_header d-lg-none">
                <div className="logo">
                  <img alt="" src={blacklogo} />
                </div>
                <img alt="" src={vector1} onClick={() => setShow(false)} />
              </div>
            </div>
            <div
              className="navigation_cart_container d-none d-lg-flex animate__animated animate__fadeIn"
              id="first-one"
            >
              {!currentUrl && (
                <div className="nav_phone_number_container" id="nav_phone">
                  <img
                    alt="white phone icon"
                    src={newphone}
                    className="white_phone"
                  />
                  <img
                    alt="black phone icon"
                    src={blackphone}
                    className="black_phone"
                  />
                  <a href="tel:(905) 766 9009">(905) 766 9009</a>
                </div>
              )}
              {!currentUrl && (
                <div className="nav_cart_container ">
                  <img alt="" src={newcart} className="cart_white" />
                  <img alt="" src={blackcart} className="cart_black" />
                  <div className="bag-navigation" onClick={openCart}>
                    cart ({selectedServices?.length + selectedPackages?.length})
                    {bagNotification && (
                      <div className="auth-bag active">
                        <div className="auth-triangle"></div>
                        <div className="bag-content"></div>
                      </div>
                    )}
                  </div>
                </div>
              )}
              <img
                alt=""
                src={humburgerwhite}
                className="humburgermenuwhite"
                onClick={() => setShow(true)}
              />
              {!user && (
                <a href="/signin" className="sign_up_button">
                  Sign in / Sign Up
                </a>
              )}
              {user && (
                <div className="person_id">
                  {user?.image && (
                    <div className="icon">
                      <img src={user?.image} alt="" />
                    </div>
                  )}
                  <div className="name name-white">
                    {nameCapitalized(user?.name)}
                    {nameCapitalized(user?.last_name)}
                  </div>
                  <span className="pop">
                    <i class="down" />
                    <div className="popMenu">
                      <a
                        href={`/user/${user?.id}?auth_token=${getAuthToken()}`}
                      >
                        <img src={userIcon} />
                        <span>Profile</span>
                      </a>
                      <a
                        href={`/user/${
                          user?.id
                        }/settings?auth_token=${getAuthToken()}`}
                      >
                        <img src={settings} />
                        <span>Settings</span>
                      </a>
                      <div onClick={logOut}>
                        <img src={logOutImg} />
                        <span>Log out</span>
                      </div>
                    </div>
                  </span>
                </div>
              )}
            </div>
          </>
        )}
      </nav>
      {popupIsOpen && (
        <div className="popup_download_information">
          <Popup setPopupIsOpen={setPopupIsOpen} />
        </div>
      )}
    </>
  );
}

export default withRouter(HeaderNavigation);
