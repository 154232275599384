import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProgressBar from "./progress-bar";
import LoadingPageContent from "../../services/loading-page-content";

import Logo from "../../../../assets/images/propertylogo.svg";
import downloadingIcon from "./icons/download-icon.svg";
import "./styles.scss";
import "animate.css";

const PageLoader = ({ loading, pageLoaded }) => {
  const [data, setData] = useState({});
  const params = useParams();
  const [isTimer, setIsTimer] = useState(false);

  useEffect(() => {
    LoadingPageContent.getLoadingPageData(params.id).then((res) => {
      setData(res.data.item);
    });
  }, []);

  useEffect(() => {
    !loading &&
      setTimeout(() => {
        setIsTimer(true);
      }, 400);
  }, [loading]);

  return (
    <div
      className={`loading-page-background animate__animated animate_fadeIn ${
        !loading && "page_loader_wrapper_out"
      } ${isTimer && "page_loader_wrapper_hide"}`}
      id="loading-page"
    >
      <div className="logo-container">
        <img width={181} height={41} src={Logo} alt="Logo" />
      </div>
      <div className="loading-main-content">
        <img
          src={downloadingIcon}
          alt="download icon"
          className="loading-page-logo"
        />
        <h1 className="loading-page-title"> Download Zone</h1>
        <h2 className="loading-page-address"> {data.address}</h2>
        <ProgressBar almostDone="80" done="20" />
        <div>
          <p className="preparing-media-text">
            Preparing media, please wait <span className="one">.</span>
            <span className="two">.</span>
            <span className="three">.</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
