import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import DownloadPageInformation from '../download-page-information/DownloadPageInformation';
import DownloadPageService from '../../services/DownloadPageService';

import './style.scss';

import closeIcon from '../../icons/popup_close.png';
import ClockBlack from '../../icons/clock.svg';

const Popup = ({ setPopupIsOpen }) => {
  const [content, setContent] = useState({});
  const params = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    DownloadPageService.getContent(params.id).then((res) => {
      if (res.status === 404) {
        navigate('/404');
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        return;
      }
      setContent(res.data.item);
    });
  }, []);

  return (
    <div className="popup_container">
      <div className="popup_wrapper">
        <div className="popup_close" onClick={() => setPopupIsOpen(false)}>
          <img src={closeIcon} alt="popupClose" />
        </div>
        <div className="popup_title_box">
          <DownloadPageInformation
            APIData={content}
            title={'DOWNLOAD CENTER'}
            agent={content?.agent?.first_name + ' ' + content?.agent?.last_name}
            shoot_date={
              content?.shoot_data
                ? moment(content?.shoot_data).format('DD/MM/YYYY')
                : '12/02/2021'
            }
            services={content?.services}
            services_packages={content?.packages?.pricing_services_list}
            customStyle="popup_info"
            clockBlack={ClockBlack}
            popup={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Popup;
