import React, { useEffect, useState } from 'react';
import FaqPageService from '../services/FaqPageService';
import FaqComponent from '../components/FaqComponent/FaqComponents';
import './style.scss';

export const Faq = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    FaqPageService.getQuestions().then((res) => {
      setData(res.data.items);
    });
  }, []);

  const faqContent = data.map((element, i) => {
    element.id = i + 1;
    return element;
  });

  const findMiddleIndex = Math.ceil(faqContent.length / 2);
  const firstSection = faqContent.splice(0, findMiddleIndex);
  const secondSection = faqContent.splice(-findMiddleIndex);

  return (
    <section className="section_faq">
      <div className="grid_box">
        <FaqComponent sections={firstSection} />
      </div>

      <div className="grid-second-box">
        <FaqComponent sections={secondSection} />
      </div>
    </section>
  );
};
