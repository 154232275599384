import { apiManager } from './apiManager';

export const propertyWebsiteApi = {
  getTourPropertyWebsite: async ({ tourId }) =>
    await apiManager.get(`/dashboard/tours/property-website/${tourId}`),
  getTourPropertyWebsiteHighlights: async () =>
    await apiManager.get('/dashboard/highlights'),
  getAllHighlights: async () => await apiManager.get('/dashboard/highlights'),
  saveOrUpdateHouseDater: async ({ tourId, params }) =>
    await apiManager.post(`/dashboard/tours/house-dater/${tourId}`, params),
  updateSalePrice: async ({ tourId, params }) =>
    await apiManager.post(`/dashboard/tours/tours/update/${tourId}`, params),
  saveOrUpdateHighlights: async ({ tourId, params }) =>
    await apiManager.post(`/dashboard/tours/highlight/${tourId}`, params),
  saveOrUpdateTourDetails: async ({ tourId, params }) =>
    await apiManager.post(`/dashboard/tours/tour-details/${tourId}`, params),
  saveOrUpdatePresentation: async ({ tourId, params }) =>
    await apiManager.post(`/dashboard/tours/presentation/${tourId}`, params),
  saveOrUpdateNeighborhood: async ({ tourId, params }) =>
    await apiManager.post(`/dashboard/tours/neighborhood/${tourId}`, params),
  saveOrUpdateAgentInfo: async ({ tourId, params, customHeaders }) =>
    await apiManager.post(
      `/dashboard/tours/agent-info/${tourId}`,
      params,
      customHeaders
    ),
  deleteAgent: async ({ agentId }) =>
    apiManager.delete(`/dashboard/tours/agent-info/delete/${agentId}`),
};
