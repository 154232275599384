import React from "react";
import "../address-holder/styles.scss";

export const AddressHolder = ({APIData }) => {
    const secondAddress = APIData?.address?.substring(APIData?.address?.indexOf(",") + 1);

    return (
        <>
            <span className="address">{secondAddress}</span>
        </>
    );
};

export default AddressHolder;
