import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { reducer as downloadZoneReducer } from "pages/download-zone/newStore";

const reducer = combineReducers({
  downloadZone: downloadZoneReducer,
});

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
  devTools: process.env.NODE_ENV !== "production",
});
