const checkData = (data, virtualStaging = false, name = '') => {
    const changedData = [[], []];
    if (!data) {
        return;
    }
    if (!!virtualStaging) {
        changedData[0].files = [...data];
        changedData[1].files = [];
        return changedData;
    }
    if (name === 'Gallery' && (typeof data === 'object' && data !== null)) {
        if (!!data?.MLS) {
            changedData[0].files = [...data?.MLS];
        }
        if (!!data?.PRINT) {
            changedData[1].files = [...data?.PRINT];
        }
        return changedData;
    }
    if (name === 'withSqFt' || name === 'withoutSqFt') {
        const jpgData = data.find(elem => elem.name === 'JPG');
        changedData[0] = jpgData;
        const pdfData = data.find(elem => elem.name === 'PDF');
        changedData[1] = pdfData;
        return changedData;
    }
    if (Array.isArray(data)) {
        return data;
    } else {
        if (!!name) {
            if (!data[name].length) {
                return;
            }
            if (data[name][0].files.length > 0) {
                return data[name];
            } else {
                changedData[0].files = [...data[name]];
            }
        } else {
            changedData[0].files = [...data];
        }
        changedData[1].files = [];
        return changedData;
    }
};

export default checkData;
