import React, { useEffect, useState } from 'react';
import './style.scss';
import DownloadProgressElement from './download-progress-element/DownloadProgressElement';
import * as arrXHR from '../../global/download-zip/script/jszip-utils';
import {
  zip,
  filesNamesArr,
  setIsDownloadedAbort,
  downloadProgressActionCreator,
} from '../../global/download-zip/download-zip';
import { store } from '../../store';
import { useSelector } from 'react-redux';

//Create constants for the SVG because when we use import we sometimes get icons after we start loading.
const photo_image = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.875 3.125H3.125V21.875H21.875V3.125Z"
      stroke="#282828"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.125 14.5834L7.29167 10.4167L18.75 21.8751"
      stroke="#282828"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0624 10.4166C15.5007 10.4166 16.6666 9.25066 16.6666 7.81242C16.6666 6.37418 15.5007 5.20825 14.0624 5.20825C12.6242 5.20825 11.4583 6.37418 11.4583 7.81242C11.4583 9.25066 12.6242 10.4166 14.0624 10.4166Z"
      stroke="#282828"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0625 17.1875L21.875 9.375"
      stroke="#282828"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const row_down = (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.01142 0L7.20231 5.5L8.50001 6.85803L9.79773 5.5L14.9885 0L17 2.10493L8.50001 11L0 2.10493L2.01142 0Z"
      fill="white"
      fill-opacity="0.85"
    />
  </svg>
);
const download_close = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L11 11M1 11L11 1"
      stroke="white"
      stroke-opacity="0.85"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
);

const DownloadProgress = ({ downloadedElements }) => {
  const [isLoadingLogOpen, setIsLoadingLogOpen] = useState(false);
  const [totalSize, setTotalSize] = useState(0);
  const [totalDownloadedSize, setTotalDownloadedSize] = useState(0);
  const [totalLoadedPercent, setTotalLoadedPercent] = useState(0);
  const [downloadFileType, setDownloadFileType] = useState('');
  const fileType = useSelector((state) => state.downloadFileType);

  const handleClose = () => {
    const isCancelDownload = window.confirm(
      'The download will stop. Continue?'
    );
    if (isCancelDownload) {
      zip.files = {};
      setIsDownloadedAbort(false);
      window.onbeforeunload = null;
      store.dispatch(downloadProgressActionCreator([]));
      arrXHR.arrXHR.map((elem) => elem.abort());
    }
  };

  const resetSizeAndPercent = () => {
    setTotalSize(0);
    setTotalDownloadedSize(0);
    setTotalLoadedPercent(0);
  };

  const countTotalInformation = () => {
    downloadedElements.map((elem) => setTotalSize((prev) => prev + elem.total));
    downloadedElements.map((elem) =>
      setTotalDownloadedSize((prev) => prev + elem.loaded)
    );
  };

  const calculateTotalLoadedPercent = () => {
    setTotalLoadedPercent(Math.floor((totalDownloadedSize * 100) / totalSize));
  };

  useEffect(() => {
    resetSizeAndPercent();
    countTotalInformation();
    calculateTotalLoadedPercent();
  }, [downloadedElements]);

  useEffect(() => {
    setDownloadFileType(fileType);
  }, [downloadedElements]);

  return (
    <div
      className={`download_progress_wrapper ${
        isLoadingLogOpen
          ? 'download_progress_wrapper_close'
          : 'download_progress_wrapper_open'
      }`}
    >
      <div className="download_progress">
        <div className="download_progress_header">
          <div className="download_progress_title">Downloading media</div>
          <div
            className={`download_progress_up_down ${
              isLoadingLogOpen
                ? 'download_progress_up_down_close'
                : 'download_progress_up_down_open'
            }`}
            onClick={() => setIsLoadingLogOpen(!isLoadingLogOpen)}
          >
            {row_down}
          </div>
          <div
            className="download_progress_close"
            onClick={() => handleClose()}
          >
            {download_close}
          </div>
        </div>
        <div className="download_progress_body">
          <div className="download_progress_icon">{photo_image}</div>
          <div className="download_progress_status">
            {`Downloading ${downloadFileType}`}
            <span className="download_progress_in_progress">
              {
                downloadedElements.filter((elem) => elem.progress === 100)
                  .length
              }
            </span>
            of
            <span className="download_progress_all">
              {downloadedElements.length}
            </span>
          </div>
        </div>
        <div className="download_progress_loading_line_wrapper">
          <div
            className="download_progress_loading_line"
            style={{ width: `${totalLoadedPercent} % ` }}
          ></div>
        </div>
        <div
          className={`download_progress_log ${
            isLoadingLogOpen
              ? 'download_progress_log_close'
              : 'download_progress_log_open'
          }`}
        >
          {downloadedElements.map((elem) => (
            <DownloadProgressElement
              name={elem.name}
              folderName={elem.folderName}
              progress={elem.progress}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DownloadProgress;
