import axios from 'axios';
import { apiManager } from './apiManager';
import { config } from 'config';

export const authApi = {
  login: async (params) =>
    axios.post(`${config.baseUrl}/dashboard/login`, params),
  signUp: async (params) =>
    axios.post(`${config.baseUrl}/dashboard/signup`, params),
  logout: async () => apiManager.post('/dashboard/logout'),
  getUser: async () => apiManager.get('/dashboard/user'),
  forgotPassword: async (params) =>
    apiManager.post('/dashboard/forgot-password', params),
  resetPassword: async (params) =>
    apiManager.post('/dashboard/reset-password', params),
};
