import moment from 'moment';

const update_checker = ({deliver_date = '', download_center_sections = []}) => {

    const convertedDateArray = [];
    download_center_sections.map(elem => {
        if (!elem.pivot.updated_date) {
            return
        }
        convertedDateArray.push(Number(moment(elem.pivot.updated_date).format('x')))
    });

    let lastDateForSections = '';
    if (convertedDateArray.length === 0) {
        return;
    } else {
        lastDateForSections = Math.max.apply(null, convertedDateArray);
    }

    let sectionsWithLastDate = download_center_sections.filter(elem => {
        return Number(moment(elem.pivot.updated_date).format('x')) === lastDateForSections;
    });

    let isDeliveryLatest = false;
    if (moment(`${deliver_date}`) > moment(lastDateForSections)) {
        isDeliveryLatest = true;
    }

    return {
        isDeliveryLatest,
        sectionsWithLastDate
    };
};

export default update_checker;
