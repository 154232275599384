import { actions } from './newStore';
import { downloadZoneApi } from 'api';

export const getContent = (id) => {
  return async (dispatch) => {
    const { data } = await downloadZoneApi.getContent(id);
    dispatch(actions.setBagData(data));
  };
};

export const getVideosById = (id) => {
  return async (dispatch) => {
    const {
      data: { data },
    } = await downloadZoneApi.getVideosById(id);
    dispatch(actions.setTourDetails(data));
  };
};
