import axios from 'axios';
import { config } from 'config';

const getConfig = async (params, headerProps) => {
  const access_token = localStorage.getItem('access_token');
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${access_token}`,
      ...headerProps,
    },
    params,
  };
};

const getPath = (url) => `${config.baseUrl}${url}`;

export const apiManager = {
  get: async (directory, parameters, customHeaders) =>
    axios.get(getPath(directory), await getConfig(parameters, customHeaders)),
  post: async (directory, body, customHeaders) => 
    axios.post(getPath(directory), body, await getConfig(null, customHeaders)),
  put: async (directory, body) =>
    axios.put(getPath(directory), body, await getConfig(null)),
  delete: async (directory) =>
    axios.delete(getPath(directory), await getConfig(null)),
  patch: async (directory, body) =>
    axios.patch(getPath(directory), body, await getConfig(null)),
};
