import React, {useState} from 'react';
import './style.scss';

const DownloadAlert = () => {
    const [hideText, setHideText] = useState(false);

    const timer = setTimeout(() => {
        setHideText(true);
        clearTimeout(timer)
    }, 5000);

    const handleOpenAlert = () => {
        setHideText(!true);
    };

    return (
        <div onClick={handleOpenAlert} className='download_alert_container'>
            <div className='download_alert_wrapper'>
                <div className='download_alert_bell'>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M15 7.5C15 8.88889 15 10.2778 15 11.6667C15 13.3333 15.5555 14.4444 16.6667 15H3.33332C4.44444 14.4444 4.99999 13.3333 4.99999 11.6667C4.99999 10.2778 4.99999 8.88889 4.99999 7.5C4.99999 4.73858 7.23857 2.5 9.99999 2.5C12.7614 2.5 15 4.73858 15 7.5Z"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M8.33332 15C8.33332 15.9205 9.07952 16.6667 9.99999 16.6667C10.9205 16.6667 11.6667 15.9205 11.6667 15"
                            stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className={`download_alert_message ${hideText ? 'download_alert_message_hide' : ''}`}>
                    Preparing files...
                </div>
            </div>
        </div>
    )
}

export default DownloadAlert;
