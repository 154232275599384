import React from 'react';
import {Provider} from 'react-redux'
import {createStore} from 'redux'

const bag = localStorage.getItem('bag')
const obj = JSON.parse(bag)
const initialState = {
    bag: obj || {
        selectedServices: [],
        selectedPackages: []
    },
    sticky: {
        sticky: false,
        scrollY: 0
    },
    downloadedItems: [],
    tourAddress: {},
    downloadFileType: '',
    services: [],
    servicesPrice: [],
    openCloseModal: false,
};

const rootReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'BAG':
            return {...state, bag: action.data}
        case 'EMPTYBAG':
            return {...state, bag: []}
        case 'USER':
            return {...state, user: action.data}
        case 'CHANGE_STICKY_MODE':
            return {
                ...state,
                sticky: {
                    sticky: action.payload.sticky,
                    scrollY: action.payload.scrollY
                }
            }
        case 'DOWNLOAD_PROGRESS':
            return {
                ...state,
                downloadedItems: [...action.payload]
            }
        case 'SET_ADDRESS':
            return {
                ...state,
                tourAddress: {...action.payload}
            }
        case 'SET_DOWNLOAD_FILE_TYPE': {
            return {
                ...state,
                downloadFileType: action.payload
            }
        }
        case 'SET_SERVICES': {
            return {
                ...state,
                services: [...action.payload]
            }
        }
        case 'SET_SERVICE_PRICE': {
            return {
                ...state,
                servicesPrice: [...action.payload]
            }
        }
        case 'OPEN_CLOSE_MODAL': {
            return {
                ...state,
                openCloseModal: action.payload
            }
        }
        default:
            return state
    }
}

export const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)


const ReduxStore = ({children}) => {

    return (
        <Provider store={store}>
            {children}
        </Provider>
    );
}

export default ReduxStore;
