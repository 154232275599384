import React, { useState, useEffect } from "react";
import "./progress-bar.scss";

const ProgressBar = ({ done, almostDone}) => {
    const [style, setStyle] = useState({});
    const startOfProgressBar = 200;

    useEffect(() => {
        setTimeout(() => {
            const newStyle = {
                opacity: 1,
                width: `calc(${almostDone}% + ${done}%)`

            };
            setStyle(newStyle);
        }, startOfProgressBar);
    }, []);

    return (
        <>
            <div className="loading-progress-bar">
                <div className="loader-show-progress" style={style}></div>
            </div>
        </>
    );
};

export default ProgressBar;
