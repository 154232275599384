import * as Scroll from 'react-scroll';

export const scroll = (top) => {
  const options = {
    duration: 500,
    delay: 50,
    isDynamic: true,
  };
  var scroll = Scroll.animateScroll;
  scroll.scrollTo(top, options);
};
